import { AWS_PASSWORD_REGEX } from "shared/constants/Regex";
import { object, ref, SchemaOf, string } from "yup";

export type SetPasswordFormObject = {
  new_password: string;
  new_password_confirmation: string;
};
export const validationSchema = <SchemaOf<SetPasswordFormObject>>object().shape({
  new_password: string().matches(AWS_PASSWORD_REGEX, "Password is invalid").required("New Password is required"),
  new_password_confirmation: string()
    .oneOf([ref("new_password"), null], "Passwords do not match")
    .required("Please confirm your new password"),
});
