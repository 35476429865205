import { Currency, Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useMemo, useState } from "react";
import { Campaign } from "shared/types/Campaign";
import { Invoice } from "shared/types/Invoice";
import { Payee } from "shared/types/Payee";
import { CreatePayoutFormObject } from "views/payouts/AddPayoutValidationSchema";

export const initialPayee = {
  email: "",
  firstName: "",
  phoneNumber: "",
  country: "",
  accountType: "BUSINESS",
  zoneCode: "",
  currency: "",
  lastName: "",
  city: "",
  postalCode: "",
  addressLine1: "",
  bankAccountType: "CHECKING",
  bankName: "",
  accountNumber: "",
  payeeAlias: "",
};

export type PayoutContextValue = {
  payout?: CreatePayoutFormObject;
  customFee: boolean;
  chargeCreator: boolean;
  monetFee: Percentage;
  partnerFee: Percentage;
  creatorTotalFee: Percentage;
  savePayee: boolean;
  selectedPayee?: Payee;
  selectedCampaign?: Campaign;
  selectedInvoice?: Invoice;
  fundingFee?: Money;
  totalPayout?: Money;
  finalPayout?: Money;
  useSavedPayee: boolean;
  remainingInvoiceFunds: Money;
  setRemainingInvoiceFunds: Dispatch<SetStateAction<Money>>;
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | undefined>>;
  setUseSavedPayee: Dispatch<SetStateAction<boolean>>;
  setFundingFee: Dispatch<SetStateAction<Money | undefined>>;
  setTotalPayout: Dispatch<SetStateAction<Money | undefined>>;
  setFinalPayout: Dispatch<SetStateAction<Money | undefined>>;
  setSelectedPayee: Dispatch<SetStateAction<Payee | undefined>>;
  setSelectedCampaign: Dispatch<SetStateAction<Campaign | undefined>>;
  getCurrency: () => Currency;
  setSavePayee: Dispatch<SetStateAction<boolean>>;
  setCreatorTotalFee: Dispatch<SetStateAction<Percentage>>;
  setMonetFee: Dispatch<SetStateAction<Percentage>>;
  setPartnerFee: Dispatch<SetStateAction<Percentage>>;
  setChargeCreator: Dispatch<SetStateAction<boolean>>;
  setPayout: Dispatch<SetStateAction<CreatePayoutFormObject | undefined>>;
  setCustomFee: Dispatch<SetStateAction<boolean>>;
  resetState: Dispatch<void>;
};

export const initialContext: PayoutContextValue = {
  payout: undefined,
  customFee: false,
  chargeCreator: true,
  monetFee: new Percentage(0),
  partnerFee: new Percentage(0),
  creatorTotalFee: new Percentage(0),
  savePayee: false,
  selectedPayee: undefined,
  fundingFee: undefined,
  totalPayout: undefined,
  finalPayout: undefined,
  selectedCampaign: undefined,
  useSavedPayee: false,
  selectedInvoice: undefined,
  remainingInvoiceFunds: new Money(0),
  setRemainingInvoiceFunds: () => {},
  setSelectedInvoice: () => {},
  setUseSavedPayee: () => {},
  setFundingFee: () => {},
  setTotalPayout: () => {},
  setFinalPayout: () => {},
  setSelectedCampaign: () => {},
  setSelectedPayee: () => {},
  getCurrency: () => "GBP" as Currency,
  setSavePayee: () => {},
  setCreatorTotalFee: () => {},
  setMonetFee: () => {},
  setPartnerFee: () => {},
  setChargeCreator: () => {},
  setPayout: () => {},
  setCustomFee: () => {},
  resetState: () => {},
};

export const PayoutContext = React.createContext<PayoutContextValue>({
  ...initialContext,
});

export const PayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [payout, setPayout] = useState<CreatePayoutFormObject>();
  const [fundingFee, setFundingFee] = useState<Money>();
  const [totalPayout, setTotalPayout] = useState<Money>();
  const [finalPayout, setFinalPayout] = useState<Money>();
  const [chargeCreator, setChargeCreator] = useState<boolean>(true);
  const [customFee, setCustomFee] = useState<boolean>(false);
  const [monetFee, setMonetFee] = useState<Percentage>(new Percentage(0));
  const [partnerFee, setPartnerFee] = useState<Percentage>(new Percentage(0));
  const [creatorTotalFee, setCreatorTotalFee] = useState<Percentage>(new Percentage(0));
  const [savePayee, setSavePayee] = useState<boolean>(false);
  const [selectedPayee, setSelectedPayee] = useState<Payee>();
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [useSavedPayee, setUseSavedPayee] = useState<boolean>(false);
  const [remainingInvoiceFunds, setRemainingInvoiceFunds] = useState<Money>(new Money(0));

  const getCurrency = () => {
    return selectedInvoice?.invoiceValue.currency as Currency;
  };

  const resetState = () => {
    setPayout(undefined);
    setTotalPayout(new Money(0));
    setFinalPayout(new Money(0));
    setFundingFee(new Money(0));
    setChargeCreator(true);
    setCustomFee(false);
    setMonetFee(new Percentage(0));
    setPartnerFee(new Percentage(0));
    setCreatorTotalFee(new Percentage(0));
    setSavePayee(false);
    setSelectedPayee(undefined);
    setSelectedCampaign(undefined);
    setSelectedInvoice(undefined);
  };

  const contextValue = useMemo(() => {
    return {
      setFundingFee,
      fundingFee,
      totalPayout,
      setTotalPayout,
      finalPayout,
      setFinalPayout,
      selectedCampaign,
      setSelectedCampaign,
      monetFee,
      setMonetFee,
      partnerFee,
      setPartnerFee,
      creatorTotalFee,
      setCreatorTotalFee,
      chargeCreator,
      setChargeCreator,
      payout,
      setPayout,
      customFee,
      setCustomFee,
      getCurrency,
      resetState,
      savePayee,
      setSavePayee,
      selectedPayee,
      setSelectedPayee,
      useSavedPayee,
      setUseSavedPayee,
      selectedInvoice,
      setSelectedInvoice,
      remainingInvoiceFunds,
      setRemainingInvoiceFunds,
    };
  }, [
    monetFee,
    remainingInvoiceFunds,
    creatorTotalFee,
    totalPayout,
    partnerFee,
    finalPayout,
    chargeCreator,
    payout,
    setFundingFee,
    fundingFee,
    customFee,
    savePayee,
    selectedPayee,
    useSavedPayee,
    selectedCampaign,
    setSelectedCampaign,
    selectedInvoice,
  ]);

  return <PayoutContext.Provider value={contextValue}>{children}</PayoutContext.Provider>;
};

const usePayoutProvider = (): PayoutContextValue => {
  return useContext(PayoutContext);
};

export default usePayoutProvider;
