import MonetAlert from "components/tailwind/MonetAlert";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo, useState } from "react";
import { PartnerCollectionAccount } from "shared/types/Partner";
import { formatSortCode } from "shared/utils/beneficiaryDetails";

const EarlyPayCollectionAccounts = () => {
  const [selectedAccount, setSelectedAccount] = useState<PartnerCollectionAccount>();
  const { partner } = useSessionProvider();

  const handleChange = (value: string) => {
    if (partner) {
      const account = partner.collectionAccounts?.find((item) => item.currency === value);
      setSelectedAccount(account);
    }
  };

  const dropdownOptions = useMemo(() => {
    if (partner?.collectionAccounts) {
      setSelectedAccount(partner.collectionAccounts[0]);
      return partner.collectionAccounts.map((account) => {
        return {
          label: `${account.accountName} (${account.currency})`,
          value: account.currency,
        };
      });
    } else return [];
  }, [partner]);

  const collectionAccount = useMemo(() => {
    if (selectedAccount) {
      return (
        <MonetAlert variant="secondary" className="mt-0">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 justify-items-start">
            <MonetLabelledItem label="Account Name" value={selectedAccount.accountName ?? "-"} arrangement="row" className="w-full" />
            <MonetLabelledItem label="Bank" value={selectedAccount.bankName ?? "-"} arrangement="row" className="w-full" />
            <MonetLabelledItem label="Sort code" value={formatSortCode(selectedAccount.sortCode ?? "-")} arrangement="row" className="w-full" />
            <MonetLabelledItem label="Account number" value={selectedAccount.accountNumber ?? "-"} arrangement="row" className="w-full" />
            <MonetLabelledItem label="IBAN" value={selectedAccount.iban ?? "-"} arrangement="row" />
            <MonetLabelledItem label="Swift / bic" value={selectedAccount.swiftCode ?? "-"} arrangement="row" className="w-full" />
          </div>
        </MonetAlert>
      );
    } else return null;
  }, [selectedAccount]);

  return (
    <div className="flex flex-col gap-4">
      <MonetCard className="overflow-visible">
        <MonetCardTitle>
          EarlyPay collection accounts
          <p className="text-sm">These are the bank accounts which you instruct clients to pay into when MONET funds your campaigns with EarlyPay</p>
        </MonetCardTitle>
        <MonetAlert variant="info" title="Invoicing" className="mt-0">
          When creating invoices for campaigns please ensure they are made out to the correct collection account for the currency you are using as below. Where possible please copy
          in <span className="font-semibold">earlypay@monet.money</span> when communicating with clients about invoices that we have funded.
        </MonetAlert>
        <TailwindSelectInput
          inputProps={{
            name: "collectionAccount",
            onChange: handleChange,
            value: selectedAccount?.currency,
          }}
          options={dropdownOptions}
          placeholder={""}
        />
        {selectedAccount ? collectionAccount : <p>No collection accounts found.</p>}
      </MonetCard>
    </div>
  );
};

export default EarlyPayCollectionAccounts;
