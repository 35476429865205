import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetLink from "components/tailwind/MonetLink";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import MonetMenuDropdown from "components/tailwind/MonetMenuDropdown";
import NoDataCard from "components/tailwind/NoDataCard";
import StatusBadge from "components/tailwind/StatusBadge";
import TailwindDisclosure from "components/tailwind/headlessTailwind/TailwindDisclosure";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Campaign } from "shared/types/Campaign";
import { isInPartnerContext } from "utils/User";
import DeleteDeliverablesModal from "./DeleteDeliverablesModal";
import UpdateDeliverablesDrawer from "./UpdateDeliverablesDrawer";

type DeliverablesSectionProps = {
  isLoading: boolean;
  refetchCampaign(): void;
  campaign?: Campaign;
};

const DeliverablesSection: React.FC<DeliverablesSectionProps> = ({ isLoading, refetchCampaign, campaign }) => {
  const { user } = useSessionProvider();
  const { openModal } = useModal();

  const deliverables = useMemo(() => {
    if (campaign && campaign.deliverables && campaign.deliverables.length > 0) {
      return campaign.deliverables?.map((deliverable) => (
        <div className="flex flex-row gap-4">
          <TailwindDisclosure
            buttonElement={
              <div className="flex flex-row justify-between gap-4">
                <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
                  <StatusBadge type="deliverable" text={deliverable.status} />
                  <p className="text-sm text-left">{deliverable.name}</p>
                </div>
              </div>
            }
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm bg-gray-50 rounded-lg p-2">
              <div>
                <p className="font-semibold">Description</p>
                <div>{deliverable.description ? <p>{deliverable.description}</p> : <p>No description</p>}</div>
              </div>
              {deliverable.liveLinks && deliverable.liveLinks.length > 0 && (
                <div>
                  <p className="font-semibold">Links to live content</p>
                  <div>
                    {deliverable.liveLinks?.map((link) => (
                      <MonetLink key={link} href={link} showIcon={true} className="text-monet-blue w-full">
                        {link}
                      </MonetLink>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {isInPartnerContext(user) && campaign.campaignStatus !== "COMPLETED" && (
              <div className="flex gap-4 w-full justify-end mt-4">
                <MonetButton
                  size="x-small"
                  variant="link"
                  className="text-red-500"
                  onClick={() => openModal(DeleteDeliverablesModal, { campaignId: campaign.campaignId, deliverable: deliverable, refetchCampaign: refetchCampaign })}
                >
                  Delete
                </MonetButton>
                <MonetButton
                  size="x-small"
                  variant="link"
                  onClick={() => openModal(UpdateDeliverablesDrawer, { campaignId: campaign.campaignId, deliverable: deliverable, refetchCampaign: refetchCampaign })}
                >
                  Edit
                </MonetButton>
              </div>
            )}
          </TailwindDisclosure>
        </div>
      ));
    } else {
      return (
        <NoDataCard showAsUnstyledCard={true}>
          No deliverables
          {campaign && isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
            <MonetButton
              size="x-small"
              variant="link"
              className="w-fit underline"
              onClick={() => openModal(UpdateDeliverablesDrawer, { campaignId: campaign.campaignId, refetchCampaign: refetchCampaign })}
            >
              Add
            </MonetButton>
          )}
        </NoDataCard>
      );
    }
  }, [campaign]);

  return (
    <MonetCard>
      <div>
        <div className="flex flex-row justify-between">
          <MonetCardTitle>Deliverables</MonetCardTitle>
          <div className="flex flex-row gap-4 min-w-fit">
            {campaign?.deliverables && campaign?.deliverables.length > 0 && (
              <>
                <div className="hidden sm:flex gap-4">
                  {isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
                    <>
                      <MonetButton size="x-small" onClick={() => openModal(UpdateDeliverablesDrawer, { campaignId: campaign.campaignId, refetchCampaign: refetchCampaign })}>
                        Add deliverable
                      </MonetButton>
                    </>
                  )}
                </div>
                <div className="block sm:hidden">
                  <MonetMenuDropdown
                    menuButtonProps={{
                      variant: "white",
                    }}
                  >
                    {isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
                      <>
                        <MonetDropdownMenuItem onClick={() => openModal(UpdateDeliverablesDrawer, { campaignId: campaign.campaignId, refetchCampaign: refetchCampaign })}>
                          Add deliverable
                        </MonetDropdownMenuItem>
                      </>
                    )}
                  </MonetMenuDropdown>
                </div>
              </>
            )}
          </div>
        </div>
        <p className="text-sm sm:max-w-[80%] lg:max-w-[60%]">
          You can attach deliverables to invoices, and once the client has approved and signed off on the deliverables, you can request funding for the invoice. To create an
          invoice, click on the "Funding" tab at the top of this page.
        </p>
      </div>
      {isLoading ? <MonetLoadingSpinner size="default" /> : <div className="flex flex-col gap-2">{deliverables}</div>}
    </MonetCard>
  );
};

export default DeliverablesSection;
