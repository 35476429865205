import { Money } from "@monet-money/money-type";
import MonetAlert from "components/tailwind/MonetAlert";
import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import { useMemo } from "react";
import { Campaign, CampaignDeliverable } from "shared/types/Campaign";
import { Invoice } from "shared/types/Invoice";
import { toast } from "sonner";
import { useRequestInvoiceFundingMutation } from "views/campaigns/graphql/mutations.generated";

type RequestInvoiceFundingModalProps = {
  invoice: Invoice;
  refetchInvoices(): void;
  campaign?: Campaign;
};

const RequestInvoiceFundingModal: React.FC<RequestInvoiceFundingModalProps> = ({ invoice, campaign, refetchInvoices }) => {
  const [requestInvoiceFunding] = useRequestInvoiceFundingMutation();

  const handleSubmit = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (campaign) {
        requestInvoiceFunding({
          variables: {
            invoiceId: invoice.invoiceId,
            campaignId: campaign.campaignId,
          },
        })
          .then(() => {
            toast.success("Success", { description: "Invoice funding request has been submitted. Please allow 24-48hrs for underwriting to be completed" });
            refetchInvoices();
          })
          .catch((err) => {
            toast.error("Request failed", { description: err.message });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  };

  const isInvalidInvoiceMsg = useMemo(() => {
    if (invoice && campaign) {
      const isInvoiceNotBilled = invoice.invoiceStatus !== "BILLED";
      const isOutstandingDeliverables = invoice.deliverables.reduce((acc: CampaignDeliverable[], deliverableId) => {
        const deliverable = campaign.deliverables?.find((item) => item.deliverableId === deliverableId);

        if (deliverable && deliverable.status !== "COMPLETED") {
          acc.push(deliverable);
        }

        return acc;
      }, []);

      if (isInvoiceNotBilled) {
        return (
          <>
            <p>
              The invoice must be marked as "Billed." This status indicates to MONET that the invoice has been sent to the client,{" "}
              <span className="font-semibold">{campaign.client.legalName}</span>
            </p>
            <p>To update the invoice status, click on the menu button in the invoice row and select “Edit invoice”</p>
          </>
        );
      } else if (isOutstandingDeliverables.length >= 1) {
        return (
          <>
            <p>
              The deliverables linked to this invoice have not yet been marked as "Completed." To request financing, please ensure all deliverables are completed and signed off by
              the client, <span className="font-semibold">{campaign.client.legalName}</span>
            </p>
            <p>To mark deliverables as completed, go the details tab and edit the associated deliverables, updating their status to "Completed" </p>
          </>
        );
      } else return null;
    } else return null;
  }, [invoice, campaign]);

  if (invoice.funding && invoice.funding.fundingStatus !== "REJECTED") {
    return null;
  }

  return (
    <TailwindDialog type={isInvalidInvoiceMsg ? "passive" : "info"} title={isInvalidInvoiceMsg ? undefined : "Request invoice funding"} onConfirmCallback={handleSubmit}>
      {isInvalidInvoiceMsg ? (
        <MonetAlert variant="warning" className="flex flex-col gap-4 text-left">
          {isInvalidInvoiceMsg}
        </MonetAlert>
      ) : (
        <>
          <p>
            You may be eligible for funding of up to {Money.fromStorageType(invoice.invoiceValue).format(true, true)} on this invoice, subject to underwriting approval. Once you
            request funding and it is approved, this invoice, campaign contract, and campaign value will be finalised and cannot be edited
          </p>
          <p className="mt-4">Would you like to proceed with requesting funding?</p>
        </>
      )}
    </TailwindDialog>
  );
};

export default RequestInvoiceFundingModal;
