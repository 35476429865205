import { ApolloError } from "@apollo/client";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import Typography from "@mui/material/Typography";
import { getEnvFormattedError } from "utils/error";

import { Box, Stack } from "@mui/system";
import React from "react";
import { ErrorMessage } from "shared/types/Error";

interface Props {
  title?: string;
  content?: string;
  error?: Error | ApolloError | ErrorMessage;
}

const ErrorView: React.FC<Props> = ({ title, content, error }) => {
  return (
    <Stack display="flex" flexDirection="column" flex={1} justifyContent="center">
      <Box textAlign="center">
        <SyncProblemIcon sx={{ fontSize: "200px" }} color="error" />
      </Box>
      <Typography variant="h2" align="center" gutterBottom>
        {title ?? "Error..."}
      </Typography>
      {content && (
        <Typography align="center" color="error">
          {content}
        </Typography>
      )}
      {error && (
        <Typography align="center" color="error">
          {getEnvFormattedError(error)}
        </Typography>
      )}
    </Stack>
  );
};

export default ErrorView;
