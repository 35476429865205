import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import { useSessionProvider } from "contexts/SessionProvider";
import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { hasAccess } from "utils/User";
import ListPartners from "./ListPartners/ListPartners";
import PartnerDetails from "./PartnerDetails/PartnerDetails";
import { PARTNERS_DETAILS_PATH, PARTNERS_LIST_ALL_PATH } from "./PartnersPath";

const PartnersRoutes: React.FC = () => {
  const { user } = useSessionProvider();
  const navigate = useNavigate();

  if (!hasAccess(user, [process.env.REACT_APP_MONET_ADMIN_GROUP], []) || user.isImpersonating) {
    navigate("/403");
  }

  return (
    <ErrorBoundary>
      <ViewLayout helmet="Partners (admin)">
        <Routes>
          <Route path={PARTNERS_LIST_ALL_PATH} element={<ListPartners />} />
          <Route path="/" element={<Navigate to={PARTNERS_LIST_ALL_PATH} />} />
          <Route path={PARTNERS_DETAILS_PATH} element={<PartnerDetails />} />
        </Routes>
      </ViewLayout>
    </ErrorBoundary>
  );
};

export default PartnersRoutes;
