import { Box, Button, Checkbox, Divider, FormControl, Stack, styled, Typography, useTheme } from "@mui/material";
import AlertView from "components/AlertView/AlertView";
import CountryFlag from "components/CountryFlag/CountryFlag";
import CustomModal from "components/CustomModal/CustomModal";
import getSymbolFromCurrency from "currency-symbol-map";
import { TextField } from "mui-rff";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useForm } from "react-final-form";
import { Currency, CurrencyWithMargin } from "shared/types/Currency";
import { getCurrencies } from "utils/currency";
import { limitDecimalPlaces } from "utils/inputs";
import { PercentageTextFieldMui } from "../PercentageTextField";
import { multiCurrencyWithMarginSchema } from "./MultiSelectCurrenciesWithMarginSchema";

type MultiSelectProps = {
  allowedCurrencies: CurrencyWithMargin[];
  name: string;
  isDisabled?: boolean;
};

const SelectedCurrency = styled(Stack, {
  shouldForwardProp: (propertyName) => !["isDisabled"].includes(propertyName.toString()),
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  alignItems: "center",
  justifyContent: "space-around",
  border: isDisabled ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.primary.main}`,
  color: isDisabled ? theme.palette.common.black : theme.palette.primary.main,
  flexDirection: "row",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    marginBottom: 0,
  },
}));

const CurrencyElement = styled(Stack, {
  shouldForwardProp: (propertyName) => !["isDisabled"].includes(propertyName.toString()),
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  flexDirection: "row",
  gap: theme.spacing(1),
  backgroundColor: isDisabled ? theme.palette.grey[100] : theme.palette.primary.light,
  padding: `${theme.spacing(1)} ${theme.spacing(2)} `,
  alignItems: "center",
}));

const CurrencyContainer = styled(Stack, {
  shouldForwardProp: (propertyName) => !["isDisabled"].includes(propertyName.toString()),
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  border: isDisabled ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const CurrenciesListItem = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",

  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    flexDirection: "row",
  },
}));

const ExistingCurrencies = styled(Stack, {
  shouldForwardProp: (propertyName) => !["isDisabled"].includes(propertyName.toString()),
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderBottom: isDisabled ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.primary.main}`,
  width: "100%",
  borderRadius: 0,

  [theme.breakpoints.up("md")]: {
    borderBottom: "none",
    borderRight: isDisabled ? "none" : `1px solid ${theme.palette.primary.main}`,
  },
}));

export const MultiSelectCurrenciesWithMargin: React.FC<MultiSelectProps> = ({ allowedCurrencies, name, isDisabled = false, ...props }) => {
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [platFormAllowedCurrencies, setPlatFormAllowedCurrencies] = useState<CurrencyWithMargin[]>(allowedCurrencies);
  const [showModal, setShowModal] = useState(false);

  const theme = useTheme();
  const { change } = useForm();

  const allowedCurrenciesList = useMemo(() => {
    return platFormAllowedCurrencies.map((cur) => cur.currency as string);
  }, [platFormAllowedCurrencies]);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleCheck = (isChecked: boolean, currency: string) => {
    if (isChecked) {
      setPlatFormAllowedCurrencies([...platFormAllowedCurrencies, { currency: currency as Currency, margin: -1 }]);
    } else {
      setPlatFormAllowedCurrencies(platFormAllowedCurrencies.filter((item) => item.currency != currency));
    }
  };

  const handleMarginInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currency: string) => {
      //Limit the input to 2pd
      const formattedValue = limitDecimalPlaces(e.target.value, 2);

      if (e.target.value !== formattedValue) {
        e.target.value = formattedValue;
      }

      const updatedAllowedCurrencies = platFormAllowedCurrencies.map((cur) => ({
        ...cur,
        margin: cur.currency == currency ? +e.target.value : cur.margin,
      }));
      setPlatFormAllowedCurrencies(updatedAllowedCurrencies);
      setErrorMessages(undefined);
    },
    [platFormAllowedCurrencies],
  );

  const handleSave = useCallback(async () => {
    try {
      await multiCurrencyWithMarginSchema.validate({ selectedCurrencies: platFormAllowedCurrencies });
    } catch (err: Error | any) {
      setErrorMessages(err.errors);
      return;
    }
    change(name, platFormAllowedCurrencies);
    toggleModal();
  }, [platFormAllowedCurrencies, name]);

  const hasSelectedCurrencies = !!allowedCurrencies.length;

  const isMarginValid = useMemo(() => {
    return !!platFormAllowedCurrencies.length && platFormAllowedCurrencies.every((item) => typeof item.margin == "number" && item.margin >= 0 && item.margin <= 100);
  }, [platFormAllowedCurrencies]);

  const renderExistingCurrencies = useMemo(
    () => (
      <ExistingCurrencies isDisabled={isDisabled}>
        {allowedCurrencies.map((selectedCurrency: CurrencyWithMargin) => (
          <SelectedCurrency key={selectedCurrency.currency} isDisabled={isDisabled}>
            {!isDisabled && <Stack sx={{ backgroundColor: theme.palette.common.white, color: theme.palette.common.black, padding: 1 }}>{selectedCurrency.margin as number}%</Stack>}
            <CurrencyElement isDisabled={isDisabled}>
              <CountryFlag countryCode={getCurrencies.find((item) => item.currency === selectedCurrency.currency)?.countryCode || ""} />
              <Typography>
                {selectedCurrency.currency}&nbsp;({getSymbolFromCurrency(selectedCurrency.currency || "")})
              </Typography>
            </CurrencyElement>
          </SelectedCurrency>
        ))}
      </ExistingCurrencies>
    ),
    [allowedCurrencies],
  );

  return (
    <FormControl {...props}>
      <CurrencyContainer py={hasSelectedCurrencies ? undefined : 2} isDisabled={isDisabled} aria-disabled={isDisabled} data-testid="currenciesWithMargin-currencyContainer">
        {hasSelectedCurrencies ? (
          <>
            {renderExistingCurrencies}
            {!isDisabled && (
              <Button disabled={isDisabled} color="primary" variant="text" data-testid="currenciesWithMargin-editCurrencyBtn" onClick={toggleModal} sx={{ width: 200 }}>
                Click to edit
              </Button>
            )}
          </>
        ) : (
          <Button disabled={isDisabled} fullWidth onClick={toggleModal} data-testid="partner-feeRules-addCurrencyBtn">
            Click to Select currencies
          </Button>
        )}
      </CurrencyContainer>
      <CustomModal title="Edit allowed currencies" modalOpen={showModal} onCancelCallback={toggleModal} onConfirmCallback={handleSave} isConfirmBtnDisabled={!isMarginValid}>
        <Typography>Add a new currency and defines it's margin or edit existing currencies</Typography>
        {errorMessages && !!errorMessages.length && <AlertView severity="error" title={errorMessages[0]} />}
        {getCurrencies.map((item, index) => {
          const itemChecked = allowedCurrenciesList.indexOf(item.currency as Currency) > -1;
          const rowId = `${name}-index-${index}`;
          const defaultMarginValue = platFormAllowedCurrencies.find((row) => row.currency == item.currency)?.margin;
          return (
            <>
              <Stack key={item.currency}>
                <CurrenciesListItem>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                      name={rowId}
                      id={rowId}
                      onChange={(e) => handleCheck(e.target.checked, item.currency)}
                      checked={itemChecked}
                      data-testid={`addPartner-feeRules-currencyMargin-checkbox-${item.currency}`}
                    />
                    <Typography>
                      <CountryFlag countryCode={item.countryCode} />
                      &nbsp;{item.currency}&nbsp;{item.name}&nbsp;({getSymbolFromCurrency(item.currency)})
                    </Typography>
                  </Stack>
                  <Stack width={{ md: "200px" }}>
                    <PercentageTextFieldMui
                      disabled={!itemChecked}
                      defaultValue={defaultMarginValue}
                      value={itemChecked ? undefined : ""}
                      onChange={(e) => {
                        handleMarginInputChange(e, item.currency);
                      }}
                      placeholder="Margin for currency"
                      data-testid={`addPartner-feeRules-currencyMargin-input-${item.currency}`}
                    />
                  </Stack>
                </CurrenciesListItem>
              </Stack>
              <Divider />
            </>
          );
        })}
      </CustomModal>

      {/* This is needed to make mui rff know of any changes */}
      <Box display="none">
        {allowedCurrencies.map((_item, index) => {
          return (
            <Box key={`allowedCurrencies-${index}`}>
              <TextField name={`${name}[${index}].currency`} />
              <TextField name={`${name}[${index}].margin`} />
            </Box>
          );
        })}
      </Box>
    </FormControl>
  );
};
