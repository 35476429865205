import { REGEX_URL } from "shared/constants/Regex";
import { Partner, PartnerInput } from "shared/types/Partner";
import { MoneySchema, PartnerAddressSchema, getRequiredMessage } from "shared/utils/validators";
import { SchemaOf, array, boolean, mixed, number, object, string } from "yup";

const FileInputSchema = object({
  file: string(),
  type: string(),
  name: string(),
});

const subscriptionSchema = object({
  costPerMonth: MoneySchema,
  creditLimit: MoneySchema,
}).required(getRequiredMessage("Subscriptions"));
const numberTypeError = "Please enter a number";

export const newPartnerSchema = <SchemaOf<PartnerInput>>(<unknown>object().shape({
  logo: FileInputSchema,
  partnerId: string()
    .matches(/^([A-Za-z0-9])([A-Za-z0-9\-\_]{1,})$/, {
      message: "Partner ID must include ONLY Alphanumeric characters, underscores and hyphens",
    })
    .required(getRequiredMessage("Partner Id")),
  name: string().min(2, "Partner Name must be at least 2 characters").required(getRequiredMessage("Partner Name")),
  country: string().length(3).required(getRequiredMessage("Partner Country")).typeError(getRequiredMessage("Partner Country")),
  returnUrl: string()
    .optional()
    .test("return-url", (val, ctx) => {
      if (val) {
        if (!val?.match(REGEX_URL)) {
          return ctx.createError({
            type: "required",
            message: "Enter a valid url",
            path: "returnUrl",
          });
        }
      }
      return true;
    }),
  showLandingPage: boolean(),
  address: PartnerAddressSchema,
  kybStatus: mixed<Partner["kybStatus"]>().oneOf<Partner["kybStatus"]>(["IN_PROGRESS", "NOT_STARTED", "NOT_VERIFIED", "VERIFIED"], "Please select a valid KYB status"),
  limits: object().required(getRequiredMessage("Limits")).shape({
    global: MoneySchema,
  }),
  defaultConfiguration: object()
    .required(getRequiredMessage("defaultConfiguration"))
    .shape({
      termsAndConditions: object().shape({
        link: string().test("terms-and-conditions", (val, ctx) => {
          if (ctx.parent.linkType !== "Generic" && !val?.match(REGEX_URL)) {
            return ctx.createError({
              type: "required",
              message: "Enter a valid Terms and Conditions",
              path: `defaultConfiguration.termsAndConditions.link`,
            });
          }
          return true;
        }),
        linkType: string().required(getRequiredMessage("Terms And Conditions Link Type")),
      }),
      invoiceAssignmentTerms: object().shape({
        linkType: string().required(getRequiredMessage("Invoice terms link type")),

        earlyPayAgreement: string().test("invoice-assignment-earlyPayAgreement", (val, ctx) => {
          if (ctx.parent.linkType !== "Generic" && !val) {
            return ctx.createError({
              type: "required",
              message: "Please upload EarlyPay terms file",
              path: `defaultConfiguration.invoiceAssignmentTerms.earlyPayAgreement`,
            });
          }
          return true;
        }),
        onboardingAgreement: string().test("invoice-assignment-onboardingAgreement", (val, ctx) => {
          if (ctx.parent.linkType !== "Generic" && !val) {
            return ctx.createError({
              type: "required",
              message: "Please upload Onboarding agreement file",
              path: `defaultConfiguration.invoiceAssignmentTerms.onboardingAgreement`,
            });
          }
          return true;
        }),
        standardTerms: string().test("invoice-assignment-standardTerms", (val, ctx) => {
          if (ctx.parent.linkType !== "Generic" && !val) {
            return ctx.createError({
              type: "required",
              message: "Please upload standard terms file",
              path: `defaultConfiguration.invoiceAssignmentTerms.standardTerms`,
            });
          }
          return true;
        }),
      }),
      payoutTtlDelta: number().min(1, "EarlyPay payout duration must be greater than or equal to 1").required(getRequiredMessage("EarlyPay payout duration")),
      allowedCurrencies: array().min(1, "At least 1 Currency is required"),
      feeSettings: object().shape({
        max: number().min(0).typeError(numberTypeError).max(100).required(getRequiredMessage("Maximum Fee")),
        default: number()
          .min(0)
          .typeError(numberTypeError)
          .test("default-outside-maximum-fee", (fee, ctx) => {
            const defaultFee = fee || 0;
            if (defaultFee > ctx.parent.max) {
              return ctx.createError({
                message: "The default fee should be equal or less than the maximum fee",
                path: `defaultConfiguration.feeSettings.default`,
              });
            }
            return true;
          })
          .max(100)
          .required(getRequiredMessage("Default Fee")),
      }),
    }),
}));
