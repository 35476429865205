import MonetAlert from "components/tailwind/MonetAlert";
import { useMemo } from "react";
import { Invoice } from "shared/types/Invoice";

type InvoiceOverDueBannerProps = {
  invoices?: Invoice[];
};

const InvoiceOverDueBanner: React.FC<InvoiceOverDueBannerProps> = ({ invoices }) => {
  const overdueInvoices = useMemo(() => {
    if (invoices) {
      return invoices.find((invoice) => invoice.invoiceStatus === "OVERDUE");
    } else return undefined;
  }, [invoices]);

  if (!overdueInvoices) return null;

  return (
    <MonetAlert title="Invoice overdue" variant="warning">
      An invoice is now overdue because MONET has not received payment from the campaign client. <br />
      Please click on the funding tab to review your invoices and reach out to the client. When contacting them, CC partners@monet.money where possible. <br />
      Please note that a late fee is being applied for each day the invoice remains unpaid.
    </MonetAlert>
  );
};

export default InvoiceOverDueBanner;
