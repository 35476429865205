import { useSessionProvider } from "contexts/SessionProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Partner } from "shared/types/Partner";
import { toast } from "sonner";
import { restrictPlatformAccess } from "utils/Partner";
import { AUTH_PATH, SIGN_IN_PATH } from "views/auth/AuthPath";
import { ORGANISATION_PATH, ORGANISATION_PROFILE_PATH } from "views/organisation/OrganisationPath";
import { DASHBOARD_PATH } from "./paths";

const PrivateRoute = () => {
  const { pathname } = useLocation();
  const { user, partner } = useSessionProvider();

  if (!user.isAuthenticated) {
    return <Navigate to={AUTH_PATH + SIGN_IN_PATH} />;
  }

  if ([DASHBOARD_PATH, `${ORGANISATION_PATH}${ORGANISATION_PROFILE_PATH}`].includes(pathname)) {
    return <Outlet />;
  }

  const restrictRoute = (partner?: Partner): boolean | string => {
    return restrictPlatformAccess(partner, user);
  };

  const redirectToDashboardPage = () => {
    toast.error(restrictRoute(partner));
    return <Navigate to="/dashboard" />;
  };

  return Boolean(restrictRoute(partner)) ? redirectToDashboardPage() : <Outlet />;
};

export default PrivateRoute;
