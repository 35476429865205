export const checkIfAllFieldsAreDirty = (currentDirtyFields: Object, formObject: Object) => {
  const requiredKeys = Object.keys(currentDirtyFields);
  const expectedKeys = Object.keys(formObject);

  return expectedKeys.every((key) => requiredKeys.includes(key));
};

export const checkIfStepHasErrors = (errors: Object, formObject: Object) => {
  const requiredKeys = Object.keys(errors);
  const expectedKeys = Object.keys(formObject);

  if (requiredKeys.length > 0) {
    return requiredKeys.every((key) => expectedKeys.includes(key));
  }
  return false;
};
