import { yupResolver } from "@hookform/resolvers/yup";
import { Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetEditSaveButton from "components/tailwind/MonetEditSaveButton";
import MonetLink from "components/tailwind/MonetLink";
import MonetCheckbox from "components/tailwind/form/MonetCheckbox";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import { useSessionProvider } from "contexts/SessionProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { Configuration } from "shared/types/Configuration";
import { toast } from "sonner";
import { getCurrencySymbol } from "utils/currency";
import { UpdateConfigurationFormObject, updateConfigurationSchema } from "views/organisation/Profile/ValidationSchemas/updateConfigurationSchema";
import { useUpdateConfigurationMutation } from "views/organisation/graphql/mutations.generated";
import { useGetConfigurationLazyQuery } from "views/organisation/graphql/queries.generated";

const EarlyPaySettings = () => {
  const [editable, setEditable] = useState(false);
  const [configuration, setConfiguration] = useState<Configuration>();

  const { partner } = useSessionProvider();

  const [getConfiguration, { data: configurationData, refetch: refetchConfiguration }] = useGetConfigurationLazyQuery({ fetchPolicy: "network-only" });
  const [updateConfiguration] = useUpdateConfigurationMutation();

  // passing default configuration for now
  useEffect(() => {
    getConfiguration({ variables: { configurationId: "DEFAULT" } });
  }, []);

  //Set configuration
  useEffect(() => {
    if (configurationData) {
      const parsedConfiguration = JSON.parse(configurationData?.getConfiguration) as Configuration;
      setConfiguration(parsedConfiguration);
    }
  }, [configurationData]);

  const onSubmit = async (formData: UpdateConfigurationFormObject) => {
    if (configuration) {
      try {
        await updateConfiguration({
          variables: {
            configuration: {
              configurationId: configuration.configurationId,
              payoutTtlDelta: formData.payoutTtlDelta!,
              returnUrl: formData.returnUrl!,
              showLanding: formData.showLanding!,
              vendorTermsAndConditions: formData.useGenericTermsAndConditions ? "Generic" : formData.vendorTermsAndConditions!,
            },
          },
        });
        toast.success("Success", { description: "EarlyPay settings have been updated" });
        refetchConfiguration();
        toggleEdit();
      } catch (error: any) {
        toast.error("Request failed", { description: "EarlyPay settings could not be updated. Please try again" });
      }
    }
  };

  const methods = useForm<UpdateConfigurationFormObject>({
    mode: "onTouched",
    resolver: yupResolver(updateConfigurationSchema) as Resolver<UpdateConfigurationFormObject>,
    values: {
      configurationId: configuration?.configurationId || "DEFAULT",
      payoutTtlDelta: Number(configuration?.payoutTtlDelta ?? 0),
      returnUrl: configuration?.returnUrl,
      showLanding: configuration?.showLanding || false,
      vendorTermsAndConditions: configuration?.termsAndConditions?.linkType === "Generic" ? "Generic terms and conditions" : configuration?.termsAndConditions?.link,
      useGenericTermsAndConditions: configuration?.termsAndConditions?.linkType === "Generic" ? true : false,
    },
  });

  const toggleEdit = useCallback(() => {
    if (editable) {
      methods.reset();
    }
    setEditable(!editable);
  }, [editable]);

  const allowedCurrencies = useMemo(() => {
    if (configuration?.allowedCurrencies) {
      return configuration.allowedCurrencies
        .map((currency) => {
          return `${currency.currency} (${getCurrencySymbol(currency.currency)})`;
        })
        .join(" ,");
    } else return "-";
  }, [configuration]);

  const useGenericVendorTerms = methods.watch("useGenericTermsAndConditions");

  const vendorTerms = useMemo(() => {
    if (editable) {
      return (
        <div>
          <MonetInput label="Vendor T&Cs" {...methods.register("vendorTermsAndConditions")} disabled={methods.formState.isSubmitting || useGenericVendorTerms} />
          {methods.formState.errors.vendorTermsAndConditions?.message && (
            <p className="text-sm text-red-500 mt-2">{methods.formState.errors.vendorTermsAndConditions?.message as string}</p>
          )}
          <MonetCheckbox id="useGenericTermsAndConditions" size="small" {...methods.register("useGenericTermsAndConditions")} disabled={methods.formState.isSubmitting}>
            Use generic MONET vendor T&Cs
          </MonetCheckbox>
        </div>
      );
    } else {
      return (
        <div className="text-sm">
          <label className="block font-semibold text-sm text-gray-800 dark:text-white">Vendor T&Cs</label>
          {configuration?.termsAndConditions?.link ? (
            <MonetLink href={configuration?.termsAndConditions?.link} showIcon={true} className="text-monet-blue">
              Terms and conditions
            </MonetLink>
          ) : (
            <p>Using generic MONET vendor T&Cs</p>
          )}
        </div>
      );
    }
  }, [configuration, editable, methods.formState]);

  return (
    <div className="flex flex-col gap-4">
      <MonetCard className="overflow-visible">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-row justify-between gap-4 mb-4">
              <MonetCardTitle>EarlyPay settings</MonetCardTitle>
              <MonetEditSaveButton
                showSaveButton={editable}
                onEditCallBack={toggleEdit}
                onCancelCallback={toggleEdit}
                saveButtonDisabled={!methods.formState.isValid || methods.formState.isSubmitting}
                loading={methods.formState.isSubmitting}
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <MonetInput
                value={partner?.limits?.global ? Money.fromStorageType(partner?.limits?.global as MoneyStorage).format(true, true) : "-"}
                label="Credit limit"
                readOnly={true}
                disabled={editable}
                tooltip="This is the total funding limit across the account"
              />
              <MonetInput
                value={configuration?.feeSettings?.max ? Percentage.fromStorageValue(configuration.feeSettings.max).format() : "-"}
                label="Maximum fee"
                readOnly={true}
                disabled={editable}
                tooltip="This is the maximum fee that can be applied to a payout"
              />
              <MonetInput
                value={allowedCurrencies}
                label="Allowed currencies"
                readOnly={true}
                disabled={editable}
                tooltip="These are the currencies that are available to you for funding"
              />
              <MonetInput
                {...methods.register("payoutTtlDelta")}
                label="Payout expiry (hours)"
                id="payoutTtlDelta"
                error={methods.formState.errors.payoutTtlDelta?.message as string}
                disabled={methods.formState.isSubmitting}
                readOnly={!editable}
                tooltip="Once a payout has been created it will automatically expiry after this value"
              />
              <MonetInput
                {...methods.register("returnUrl")}
                label="Return URL"
                id="returnUrl"
                error={methods.formState.errors.returnUrl?.message as string}
                disabled={methods.formState.isSubmitting}
                readOnly={!editable}
                tooltip="Once a vendor has claimed a payout they are redirected to this URL"
              />
              {editable ? (
                <MonetSwitch {...methods.register("showLanding")} label="Show landing page" disabled={methods.formState.isSubmitting} />
              ) : (
                <MonetInput
                  label="Show landing page"
                  value={configuration?.showLanding ? "Yes" : "No"}
                  error={methods.formState.errors.showLanding?.message}
                  readOnly={true}
                  tooltip={`If turned ON the check out landing page will be displayed with the ${partner?.name} logo. This is the first page that a vendor will see when claiming a payout`}
                />
              )}
              {vendorTerms}
            </div>
          </form>
        </FormProvider>
      </MonetCard>
    </div>
  );
};

export default EarlyPaySettings;
