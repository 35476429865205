import { Payee } from "types/Payee";

/**
 *
 * @param value string
 * @returns string
 */
export const formatSortCode = (value: string): string => {
  let newValue = (value ?? "").toString().replace(/\D/g, "");
  if (newValue.length >= 2) {
    newValue = newValue.substring(0, 2) + "-" + newValue.substring(2);
  }
  if (newValue.length >= 5) {
    newValue = newValue.substring(0, 5) + "-" + newValue.substring(5);
  }
  return newValue;
};

/**
 * Format bsb number
 * @param value string
 * @returns string
 */
export const formatBsbNumber = (value: string): string => {
  let newValue = (value ?? "").toString().replace(/\D/g, "");
  if (newValue.length >= 3) {
    newValue = newValue.substring(0, 3) + "-" + newValue.substring(3);
  }
  return newValue;
};

export const beneficiaryDetailsToBankAccountInfo = (details: Payee): { friendlyName: string; creditPartyIdentifier: string } => {
  let friendlyName: string | undefined = details.firstName;
  if (details.firstName && details.lastName) {
    friendlyName = `${details.firstName} ${details.lastName}`;
  } else if (details.businessName) {
    friendlyName = details.businessName;
  }

  let creditPartyIdentifier: string | undefined = "";
  if (details.accountNumber) {
    creditPartyIdentifier = details.accountNumber;
  } else {
    creditPartyIdentifier = details.routingNumber ?? details.bankSwiftBic ?? details.accountNumber;
  }
  return { friendlyName, creditPartyIdentifier };
};

export const formatAccountNumber = (value: string) => {
  return (value ?? "").replace(/\D/g, "");
};
