import { Money, MoneyStorage } from "@monet-money/money-type";
import { ReactComponent as ExternalLink } from "assets/tailwind/icons/external-link.svg";
import { ReactComponent as EditIcon } from "assets/tailwind/icons/pen-line.svg";
import { ReactComponent as DeleteIcon } from "assets/tailwind/icons/trash-2.svg";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetMetric from "components/tailwind/MonetMetric";
import StatusBadge from "components/tailwind/StatusBadge";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DATETIME_STRING_FORMAT } from "shared/constants/Constants";
import { Campaign } from "shared/types/Campaign";
import { Invoice } from "shared/types/Invoice";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { useGetInvoicesByCampaignQuery } from "views/campaigns/graphql/queries.generated";
import FundingAvailableBanner from "../../banners/FundingAvailableBanner";
import InvoiceOverDueBanner from "../../banners/InvoiceOverDueBanner";
import DeleteCampaignModal from "./DeleteCampaignModal";
import EditCampaignDrawer from "./EditCampaignDrawer";
import EditClientDetailsDrawer from "./EditClientDetailsDrawer";
import DeliverablesSection from "./deliverablesSection/DeliverablesSection";
import DocumentSection from "./documentSection/DocumentSection";

type DetailsTabProps = {
  isLoading: boolean;
  refetchCampaign(): void;
  campaign?: Campaign;
};

const DetailsTab: React.FC<DetailsTabProps> = ({ campaign, isLoading, refetchCampaign }) => {
  const [invoices, setInvoices] = useState<Invoice[]>();

  const { user } = useSessionProvider();
  const { openModal } = useModal();

  const { data: campaignInvoices, loading: isInvoiceLoading } = useGetInvoicesByCampaignQuery({
    fetchPolicy: "cache-first",
    variables: {
      campaignId: campaign?.campaignId as string,
      partnerId: isInAdminContext(user) ? campaign?.partnerId : undefined,
    },
  });

  useEffect(() => {
    if (campaignInvoices) {
      const invoices = JSON.parse(campaignInvoices.getInvoicesByCampaign) as Invoice[];
      if (invoices.length > 0) {
        setInvoices(invoices);
      } else {
        setInvoices(undefined);
      }
    }
  }, [campaignInvoices]);

  return (
    <div className="flex flex-col gap-6">
      <InvoiceOverDueBanner invoices={invoices} />
      <FundingAvailableBanner invoices={invoices?.length ?? 0} campaign={campaign} isLoading={isInvoiceLoading || isLoading} />
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric loading={isLoading} label="Campaign status" value={<StatusBadge type="campaign" text={campaign?.campaignStatus!} />} />
        <MonetMetric loading={isLoading} label="Client" value={campaign?.client?.legalName} />
        <MonetMetric loading={isLoading} label="Brand" value={campaign?.brandPromoted} />
        <MonetMetric loading={isLoading} label="Campaign value" value={Money.fromStorageType(campaign?.campaignValue as MoneyStorage).format(true, true)} />
      </div>
      <MonetCard>
        <div className="flex flex-row justify-between gap-4">
          <MonetCardTitle>Details</MonetCardTitle>
          {isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
            <MonetButton size="x-small" onClick={() => openModal(EditCampaignDrawer, { campaign: campaign, invoices: invoices, refetchCampaign: refetchCampaign })}>
              <EditIcon />
              Edit
            </MonetButton>
          )}
        </div>
        <MonetBorderedCardContent className="grid grid-cols-1 gap-1 xl:grid-cols-2 xl:gap-6">
          <div className="flex flex-col gap-1">
            <MonetLabelledItem label="Name" value={campaign?.campaignName} />
            <MonetLabelledItem label="Brand promoted" value={campaign?.brandPromoted} />
            <MonetLabelledItem
              label="Client"
              value={
                <MonetButton
                  variant="link"
                  size="fit-content"
                  onClick={() =>
                    openModal(EditClientDetailsDrawer, {
                      campaign: campaign,
                      invoices: invoices,
                      refetchCampaign: refetchCampaign,
                    })
                  }
                >
                  {campaign?.client.legalName}
                  <ExternalLink width={14} height={14} />
                </MonetButton>
              }
            />
            <MonetLabelledItem label="Brief" value={campaign?.brief} />
          </div>
          <div className="flex flex-col gap-1">
            <MonetLabelledItem label="Campaign value" value={Money.fromStorageType(campaign?.campaignValue as MoneyStorage).format(true, true)} />
            <MonetLabelledItem label="Campaign ID" value={campaign?.campaignId} />
            <MonetLabelledItem
              label="Created date"
              tooltip="The date on which the campaign was created in the platform"
              value={dayjs(campaign?.createdAt).format(DATETIME_STRING_FORMAT)}
            />
          </div>
        </MonetBorderedCardContent>
      </MonetCard>
      <DeliverablesSection isLoading={isLoading} refetchCampaign={refetchCampaign} campaign={campaign} />
      <DocumentSection isLoading={isLoading} campaign={campaign} refetchCampaign={refetchCampaign} />
      {isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
        <MonetCard className="items-end">
          <MonetButton size="x-small" color="red-solid" onClick={() => openModal(DeleteCampaignModal, { campaign: campaign })}>
            <DeleteIcon /> Delete campaign
          </MonetButton>
        </MonetCard>
      )}
    </div>
  );
};

export default DetailsTab;
