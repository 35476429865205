import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Payout } from "shared/types/Payout";
import { toast } from "sonner";
import { PAYOUTS_PATH, PAYOUT_LIST_ALL_PATH } from "../../PayoutPath";
import { useDeletePayoutMutation } from "../../graphql/mutation.generated";

type DeletePayoutModalProps = {
  payout: Payout;
};

const DeletePayoutModal: React.FC<DeletePayoutModalProps> = ({ payout }) => {
  const navigate = useNavigate();

  const [deletePayout] = useDeletePayoutMutation();

  const handleDelete = (): Promise<boolean> => {
    return new Promise((resolve) => {
      deletePayout({
        variables: {
          payoutId: payout.payoutId,
          partnerId: payout.partnerId,
        },
      })
        .then(() => {
          navigate(PAYOUTS_PATH + PAYOUT_LIST_ALL_PATH);
          toast.success("Success", { description: "Payout has been deleted" });
        })
        .catch((err) => {
          toast.error("Request failed", { description: `Payout could not be deleted. ${err.message}` });
        })
        .finally(() => {
          resolve(true);
        });
    });
  };

  return (
    <TailwindDialog type="danger" title="Delete payout" submitButtonColor="red-solid" onConfirmCallback={handleDelete}>
      Are you sure? You will be permanently deleting this payout. This action can not be undone
    </TailwindDialog>
  );
};

export default DeletePayoutModal;
