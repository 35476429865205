import { yupResolver } from "@hookform/resolvers/yup";
import { Money } from "@monet-money/money-type";
import ErrorView from "components/ErrorView/ErrorView";
import MonetCard from "components/tailwind/MonetCard";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import MonetStepper from "components/tailwind/MonetStepper";
import usePayoutProvider from "contexts/PayoutProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/Campaign";
import { Configuration } from "shared/types/Configuration";
import { Invoice } from "shared/types/Invoice";
import { Payee } from "shared/types/Payee";
import { Payout } from "shared/types/Payout";
import { toast } from "sonner";
import { checkIfAllFieldsAreDirty, checkIfStepHasErrors } from "utils/form";
import {
  CreatePayoutFormObject,
  addPayoutSchema,
  createPayoutDefaultValues,
  defaultPayoutDetailsValue,
  defaultPayoutSettingsValue,
  defaultPayoutSummaryValue,
} from "../AddPayoutValidationSchema";
import { useCreatePayoutMutation } from "../graphql/mutation.generated";
import {
  useGetCampaignsQuery,
  useGetConfigurationQuery,
  useGetInvoicesByCampaignLazyQuery,
  useGetPayeesQuery,
  useGetPayoutsByCampaignLazyQuery,
} from "../graphql/queries.generated";
import PayoutDetailsStep from "./components/PayoutDetailsStep";
import PayoutDoneStep from "./components/PayoutDoneStep";
import PayoutSettingsStep from "./components/PayoutSettingsStep";
import PayoutSummaryStep from "./components/PayoutSummaryStep";

type CreatePayoutProps = {
  payoutType: Payout["payoutType"];
};

const CreatePayout: React.FC<CreatePayoutProps> = ({ payoutType }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [configuration, setConfiguration] = useState<Configuration>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false);
  const [payout, setPayout] = useState<Payout>();
  const [payees, setPayees] = useState<Payee[]>([]);
  const [campaignPayouts, setCampaignPayouts] = useState<Payout[]>();

  const { partner } = useSessionProvider();
  const { chargeCreator, monetFee, partnerFee, selectedPayee, selectedCampaign, selectedInvoice, setRemainingInvoiceFunds, remainingInvoiceFunds } = usePayoutProvider();

  const [createPayout] = useCreatePayoutMutation();
  const { data: campaignData, error: campaignError, loading: isCampaignLoading } = useGetCampaignsQuery({ fetchPolicy: "network-only" });
  const { data: getPayeesData, refetch: refetchPayees } = useGetPayeesQuery({ fetchPolicy: "network-only" });
  const [getInvoices, { data: getInvoiceData, loading: isInvoicesLoading }] = useGetInvoicesByCampaignLazyQuery({ fetchPolicy: "network-only" });
  const [getCampaignPayouts, { data: getCampaignPayoutsData, loading: isCampaignPayoutsLoading }] = useGetPayoutsByCampaignLazyQuery({ fetchPolicy: "network-only" });

  const {
    data: configurationData,
    loading: isConfigurationLoading,
    error: configurationError,
  } = useGetConfigurationQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId: partner?.partnerId,
      configurationId: "DEFAULT",
    },
  });

  useEffect(() => {
    if (selectedCampaign) {
      getInvoices({
        variables: {
          campaignId: selectedCampaign.campaignId,
        },
      });
      getCampaignPayouts({
        variables: {
          campaignId: selectedCampaign.campaignId,
        },
      });
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (getInvoiceData) {
      const invoices = getInvoiceData.getInvoicesByCampaign;
      setInvoices(JSON.parse(invoices));
    }
  }, [getInvoiceData]);

  useEffect(() => {
    if (getCampaignPayoutsData) {
      const payouts = getCampaignPayoutsData.getPayoutsByCampaign;
      setCampaignPayouts(JSON.parse(payouts));
    }
  }, [getInvoiceData]);

  useEffect(() => {
    if (getPayeesData) {
      const payees = JSON.parse(getPayeesData.getPayees) as Payee[];
      // Remove unnecessary fields createdAt, partnerId
      const formattedPayees = payees.filter((x) => x.payeeType == payoutType);
      setPayees(formattedPayees);
    }
  }, [getPayeesData]);

  useEffect(() => {
    if (configurationData) {
      setConfiguration(JSON.parse(configurationData.getConfiguration));
    }
  }, [configurationData]);

  useEffect(() => {
    if (campaignData) {
      setCampaigns((JSON.parse(campaignData.getCampaigns) as Campaign[]).filter((campaign) => campaign.campaignStatus === "ACTIVE" && campaign.budgetLimit.amount > 0) ?? []);
    }
  }, [campaignData]);

  useEffect(() => {
    const invoicePayouts = campaignPayouts?.filter((payout) => payout.invoiceId === selectedInvoice?.invoiceId);
    if (invoicePayouts) {
      const currentValueOfPayouts = invoicePayouts.reduce(
        (result, payout) => {
          return result.add(payout.payoutValue);
        },
        new Money(0, selectedInvoice?.invoiceValue.currency),
      );
      setRemainingInvoiceFunds(Money.fromStorageType(selectedInvoice?.funding?.principalAmount).subtract(currentValueOfPayouts));
    }
  }, [selectedInvoice, campaignPayouts]);

  const methods = useForm<CreatePayoutFormObject>({
    mode: "onTouched",
    defaultValues: {
      ...createPayoutDefaultValues,
    },
    resolver: yupResolver(addPayoutSchema) as Resolver<CreatePayoutFormObject>,
    context: {
      remainingFunds: remainingInvoiceFunds,
      maxFee: configuration?.feeSettings?.max,
    },
  });

  const onSubmit = async (values: CreatePayoutFormObject) => {
    setFormSubmitted(false);
    try {
      let fee;
      if (chargeCreator) {
        fee = monetFee.add(partnerFee).percentage;
      } else {
        fee = 0;
      }

      await createPayout({
        variables: {
          payout: {
            campaignId: selectedCampaign!.campaignId,
            payeeId: selectedPayee!.payeeId,
            payoutType: payoutType,
            payoutTotal: values.payoutRequest!,
            fee: fee,
            invoiceId: selectedInvoice!.invoiceId,
          },
        },
      }).then((res) => {
        const payout = JSON.parse(res.data?.createPayout) as Payout;
        setPayout(payout);
        setFormSubmissionSuccess(true);
      });
    } catch (error: any) {
      setFormSubmissionSuccess(false);
      toast.error("Request failed", { description: error.message });
    } finally {
      setFormSubmitted(true);
    }
  };

  const stepperSteps = [
    {
      index: 1,
      label: "Details",
      content: (
        <PayoutDetailsStep payoutType={payoutType} campaigns={campaigns} payees={payees} invoices={invoices} refetchPayees={refetchPayees} isInvoicesLoading={isInvoicesLoading} />
      ),
    },
    { index: 2, label: "Settings", content: <PayoutSettingsStep payoutType={payoutType} isPayoutsLoading={isCampaignPayoutsLoading} /> },
    { index: 3, label: "Summary", content: <PayoutSummaryStep payoutType={payoutType} /> },
  ];

  const isStepValid = useMemo(() => {
    if (currentStep === 1) {
      return checkIfAllFieldsAreDirty(methods.formState.dirtyFields, defaultPayoutDetailsValue) && !checkIfStepHasErrors(methods.formState.errors, defaultPayoutDetailsValue);
    } else if (currentStep === 2) {
      return checkIfAllFieldsAreDirty(methods.formState.dirtyFields, { payoutRequest: undefined }) && !checkIfStepHasErrors(methods.formState.errors, defaultPayoutSettingsValue);
    } else if (currentStep === 3) {
      return checkIfAllFieldsAreDirty(methods.formState.dirtyFields, defaultPayoutSummaryValue) && !checkIfStepHasErrors(methods.formState.errors, defaultPayoutSummaryValue);
    } else return true;
  }, [currentStep, methods.formState]);

  if (isCampaignLoading || isConfigurationLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <MonetLoadingSpinner size="large" />
      </div>
    );
  }

  if (campaignError || configurationError) {
    return <ErrorView title="Failed to load. Please try again" />;
  }

  return (
    <div className="flex justify-center h-full w-full">
      <MonetCard className="w-full max-w-[600px] h-fit overflow-visible">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MonetStepper
              stepperId="create-payout-stepper"
              steps={stepperSteps}
              currentStep={currentStep}
              formSubmitted={formSubmitted}
              formSubmissionSuccess={formSubmissionSuccess}
              finalStep={<PayoutDoneStep payout={payout} payoutType={payoutType} />}
              currentStepCallback={setCurrentStep}
              loading={methods.formState.isSubmitting}
              isButtonDisabled={!isStepValid || methods.formState.isSubmitting}
            />
          </form>
        </FormProvider>
      </MonetCard>
    </div>
  );
};

export default CreatePayout;
