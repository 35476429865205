import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import StatusBadge from "components/tailwind/StatusBadge";
import dayjs from "dayjs";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DEFAULT_DATE_FORMAT, PAYOUT_STATUSES } from "shared/constants/Constants";
import { Campaign } from "shared/types/Campaign";
import { Payout } from "shared/types/Payout";
import { getCampaignField } from "utils/Campaign";
import { PAYOUTS_PATH, PAYOUT_DETAILS } from "views/payouts/PayoutPath";

type PayoutsTableProps = {
  payouts: Payout[];
  campaigns: Campaign[];
  isPayoutsLoading: boolean;
  actionItems?: Array<{
    key: string;
    element: React.ReactNode;
    isPrimaryAction?: boolean;
    visible?: boolean;
    hideInMobileMenu?: boolean;
  }>;
};
const PayoutsTable: React.FC<PayoutsTableProps> = ({ payouts, campaigns, isPayoutsLoading, actionItems }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "payoutStatus",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueOptions: PAYOUT_STATUSES,
      renderCell: (params: GridRenderCellParams<Payout>) => {
        return <StatusBadge type="payout" text={params.row.payoutStatus} />;
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign name",
      flex: 1,
      minWidth: 250,
      valueGetter: (value, row) => getCampaignField("campaignName", row.campaignId, campaigns),
    },
    { field: "payee.email", headerName: "Email", minWidth: 300, valueGetter: (value, row) => row.payee.email },
    {
      field: "payoutValue",
      headerName: "Payout value",
      type: "number",
      flex: 1,
      minWidth: 150,
      valueGetter: (value, row) => {
        return Number(Money.fromStorageType(value as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Payout>) => {
        return formatStorageType(params.row.payoutValue as MoneyStorage);
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      type: "date",
      minWidth: 150,
      valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
      valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : "N/A"),
    },
  ];

  const handleRowClick = (params: any) => {
    const path = `${PAYOUTS_PATH}${PAYOUT_DETAILS}?${createSearchParams({
      payoutId: params.row.payoutId as string,
    }).toString()}`;

    navigate(path);
  };

  return (
    <CustomDataGrid
      enableExport={false}
      hasFooter={false}
      rows={payouts}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "createdAt",
              sort: "desc",
            },
          ],
        },
      }}
      title="Latest payouts"
      autoPageSize
      hideFooterPagination
      isDataLoading={isPayoutsLoading}
      toolbarActionItems={actionItems}
      onRowClick={handleRowClick}
      getRowId={(row: Payout) => row.payoutId}
      sx={{
        height: 400,
      }}
    />
  );
};

export default PayoutsTable;
