import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import ErrorView from "components/ErrorView/ErrorView";
import React, { useEffect, useState } from "react";
import { Partner } from "shared/types/Partner";
import { EP3User } from "utils/User";
import { useGetUsersQuery } from "../graphql/queries.generated";
import DeleteUserButton from "./DeleteUserButton";

const PartnerUsers: React.FC<{ partnerId: string; partnerKybStatus: Partner["kybStatus"] }> = ({ partnerId, partnerKybStatus }) => {
  const { data: partnerUsersData, error: partnerUsersError, loading: isLoadingPartnerUsers } = useGetUsersQuery({ variables: { partnerId }, fetchPolicy: "network-only" });
  const [users, setUsers] = useState<EP3User[]>([]);

  const columns: GridColDef<EP3User>[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      valueGetter: (value, row) => `${row.firstName} ${row.lastName}`,
      sortComparator: (a, b) => {
        return a.localeCompare(b);
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      minWidth: 200,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<any, EP3User, any>) => {
        return (
          <DeleteUserButton
            user={params.row}
            onDeleteCallback={() => {
              setUsers([...users.filter((item) => item.email !== params.row.email)]);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (partnerUsersData) {
      setUsers(JSON.parse(partnerUsersData.getUsers) as EP3User[]);
    }
  }, [partnerUsersData]);

  if (partnerUsersError) return <ErrorView title="Error loading users" error={partnerUsersError} />;

  return (
    <div className="min-h-[400px]">
      <CustomDataGrid
        isDataLoading={isLoadingPartnerUsers}
        rows={users}
        columns={columns}
        getRowId={(row: EP3User) => row.email}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </div>
  );
};

export default PartnerUsers;
