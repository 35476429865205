import { Currency, Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import ErrorView from "components/ErrorView/ErrorView";
import BookOnboardingCallBanner from "components/onboarding/BookOnboardingCallBanner";
import MonetMetric from "components/tailwind/MonetMetric";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useEffect, useMemo, useState } from "react";
import { Campaign } from "shared/types/Campaign";
import { Payout } from "shared/types/Payout";
import { isPartnerVerified } from "utils/Partner";
import PayoutsTable from "../../../components/Tables/PayoutsTable";
import { useGetCampaignsQuery, useGetPayoutsQuery } from "../graphql/queries.generated";

const ListPayouts: React.FC = () => {
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [aggregations, setAggregations] = useState({
    pendingPayouts: 0,
    completedPayouts: 0,
    agencyEarnings: new Money(0, Currency.GBP),
    expiredPayouts: 0,
  });

  const { partner, user } = useSessionProvider();

  const { data: payoutsData, error: payoutsError, loading: isPayoutsLoading } = useGetPayoutsQuery({ fetchPolicy: "network-only" });
  const { data: campaignsData, loading: isCampaignsLoading } = useGetCampaignsQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (payoutsData && campaignsData) {
      const parsedPayouts = JSON.parse(payoutsData.getPayouts) as Payout[];

      const totals = {
        pendingPayouts: 0,
        completedPayouts: 0,
        agencyEarnings: new Money(0, Currency.GBP),
        expiredPayouts: 0,
      };

      parsedPayouts.forEach((payout: Payout) => {
        if (["CRATED", "MONET_ACCEPTED", "PAYMENT_PROCESSING", "BENEFICIARY_ACCEPTED"].includes(payout.payoutStatus)) {
          totals.pendingPayouts++;
        }
        if (["SETTLED", "BENEFICIARY_PAID"].includes(payout.payoutStatus)) {
          totals.completedPayouts++;
        }
        if (["EXPIRED"].includes(payout.payoutStatus)) {
          totals.expiredPayouts++;
        }

        if (payout.feeSettings.chargeCreator === true) {
          totals.agencyEarnings = totals.agencyEarnings.add(
            Money.fromStorageType(payout.payoutValue as MoneyStorage).multiply(Percentage.fromStorageValue(payout.feeSettings.partnerFee).percentage).amount,
          );
        }

        setCampaigns(JSON.parse(campaignsData.getCampaigns));
        setPayouts(parsedPayouts);
        setAggregations(totals);
      });
    }
  }, [payoutsData, campaignsData]);

  const isLoading = useMemo(() => isPayoutsLoading || isCampaignsLoading, [isPayoutsLoading, isCampaignsLoading]);

  if (!isPartnerVerified(partner, user)) {
    return (
      <div className="flex flex-col gap-6">
        <MonetViewTitle>Payouts</MonetViewTitle>
        <BookOnboardingCallBanner />
      </div>
    );
  }

  if (payoutsError) {
    return <ErrorView title="Error loading payouts" error={payoutsError} />;
  }

  return (
    <>
      <MonetViewTitle>Payouts</MonetViewTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric
          label="Pending payouts"
          tooltip="Total payouts in statuses created, Monet accepted, beneficiary accepted or payment processing"
          loading={isLoading}
          value={aggregations.pendingPayouts}
        />
        <MonetMetric label="Completed payouts" tooltip="Total payouts in statuses beneficiary paid or settled" loading={isLoading} value={aggregations.completedPayouts} />
        <MonetMetric
          label="Agency earnings"
          tooltip="An estimate of your earnings when charging fees to vendors. This is a estimated value, no FX rate has been applied"
          loading={isLoading}
          value={aggregations.agencyEarnings.format(true, true)}
        />
        <MonetMetric label="Expired payouts" tooltip="Total payouts in status expired" loading={isLoading} value={aggregations.expiredPayouts} />
      </div>
      <PayoutsTable payouts={payouts} campaigns={campaigns} showCampaignName isLoading={isLoading} />
    </>
  );
};

export default ListPayouts;
