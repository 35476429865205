import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams } from "@mui/x-data-grid";
import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import DeleteAccountModal from "components/tailwind/DeleteAccountModal";
import MonetBadge from "components/tailwind/MonetBadge";
import MonetButton from "components/tailwind/MonetButton";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useState } from "react";
import { EP3User, isInPartnerContext } from "utils/User";
import { useGetUsersQuery } from "views/partners/graphql/queries.generated";
import AddUserDrawer from "../AddUserDrawer";

const UsersTable = () => {
  const [users, setUsers] = useState<EP3User[]>([]);

  const { openModal } = useModal();
  const { partner, user } = useSessionProvider();

  const { data, loading, refetch: refetchUsers } = useGetUsersQuery({ variables: { partnerId: partner!.partnerId! }, fetchPolicy: "cache-first" });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: isInPartnerContext(user),
  });

  useEffect(() => {
    if (data) {
      setUsers(JSON.parse(data.getUsers) as EP3User[]);
    }
  }, [data]);

  const columns: GridColDef<EP3User>[] = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (value, row) => `${row.firstName} ${row.lastName}`,
      minWidth: 200,
      sortComparator: (a, b) => {
        return a.localeCompare(b);
      },
    },
    {
      field: "email",
      minWidth: 250,
      headerName: "Email",
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      minWidth: 200,
    },
    {
      field: "role",
      minWidth: 150,
      headerName: "Role",
    },
    {
      field: "mfaEnabled",
      headerName: "2-FA",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<any, EP3User, any>) => {
        return <MonetBadge variant={params.row.mfaEnabled ? "green" : "red"}>{params.row.mfaEnabled ? "Enabled" : "Disabled"}</MonetBadge>;
      },
    },
    {
      type: "actions",
      field: "Actions",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any, EP3User, any>) => {
        return (
          <div className="flex flex-1 justify-end">
            <MonetButton
              variant="link"
              className="text-red-600"
              size="x-small"
              onClick={() => openModal(DeleteAccountModal, { onSuccessCallback: refetchUsers, user: params.row })}
            >
              Delete account
            </MonetButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <CustomDataGrid
        toolbarActionItems={[
          {
            key: "add-user",
            visible: isInPartnerContext(user),
            isPrimaryAction: true,
            element: (
              <MonetButton size="x-small" className="hidden sm:flex" onClick={() => openModal(AddUserDrawer, { refetchUsers: refetchUsers })}>
                <PlusIcon />
                Add new user
              </MonetButton>
            ),
          },
          {
            key: "add-user-mobile",
            visible: isInPartnerContext(user),
            element: (
              <MonetDropdownMenuItem className="block sm:hidden" onClick={() => openModal(AddUserDrawer, { refetchUsers: refetchUsers })}>
                Add new user
              </MonetDropdownMenuItem>
            ),
          },
        ]}
        title="Users"
        isDataLoading={loading}
        columns={columns}
        getRowId={(row: EP3User) => row.email}
        rows={users}
        sx={{ minHeight: 500 }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </>
  );
};

export default UsersTable;
