import { yupResolver } from "@hookform/resolvers/yup";
import { Money, MoneyStorage } from "@monet-money/money-type";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetButton from "components/tailwind/MonetButton";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import SignedUrl from "components/tailwind/SignedUrl";
import MonetDatePicker from "components/tailwind/form/MonetDatePicker";
import MonetFileInput from "components/tailwind/form/MonetFileInput";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetMoneyInput from "components/tailwind/form/MonetMoneyInput";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { DUE_DATE_FORMAT, USER_SELECTABLE_INVOICE_STATUSES } from "shared/constants/Constants";
import { Campaign } from "shared/types/Campaign";
import { Invoice } from "shared/types/Invoice";
import { CreateInvoiceFormObject, UpdateInvoiceFormObject, updateInvoiceSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { enumToNiceString } from "utils/strings";
import { useUpdateInvoiceMutation } from "views/campaigns/graphql/mutations.generated";
import { uploadCampaignFiles } from "views/campaigns/utils";

type UpdateInvoiceDrawerProps = {
  refetchInvoices(): void;
  invoice: Invoice;
  campaign?: Campaign;
};

const UpdateInvoiceDrawer: React.FC<UpdateInvoiceDrawerProps> = ({ invoice, campaign, refetchInvoices }) => {
  const [selectedDeliverables, setSelectedDeliverables] = useState<number[]>(invoice.deliverables);
  const [changeInvoiceDocument, setChangeInvoiceDocument] = useState(false);

  const { user } = useSessionProvider();
  const { closeModal } = useModal();

  const [updateInvoice] = useUpdateInvoiceMutation();

  const methods = useForm<UpdateInvoiceFormObject>({
    mode: "onTouched",
    resolver: yupResolver(updateInvoiceSchema) as Resolver<any>,
    defaultValues: {
      invoiceStatus: invoice.invoiceStatus,
      invoiceDocument: undefined,
      invoiceNumber: invoice.invoiceNumber,
      invoiceValue: Money.fromStorageType(invoice.invoiceValue as MoneyStorage).format(false, false),
      dueDate: [dayjs(invoice.dueDate).toDate()],
      paymentTerms: invoice.paymentTerms!,
      deliverables: selectedDeliverables,
    },
  });

  const onSubmit = async (formData: UpdateInvoiceFormObject) => {
    if (campaign) {
      try {
        let newInvoiceDocument = undefined;
        if (formData.invoiceDocument) {
          newInvoiceDocument = await uploadCampaignFiles<CreateInvoiceFormObject["invoiceDocument"]>(formData.invoiceDocument, user, campaign.campaignId);
        }

        await updateInvoice({
          variables: {
            campaignId: campaign.campaignId,
            invoice: {
              invoiceId: invoice.invoiceId,
              invoiceStatus: formData.invoiceStatus,
              invoiceValue: formData.invoiceValue,
              invoiceNumber: formData.invoiceNumber,
              deliverables: formData.deliverables!,
              paymentTerms: formData.paymentTerms!,
              dueDate: dayjs(formData.dueDate[0]).format(DUE_DATE_FORMAT),
              ...(newInvoiceDocument && { invoiceDocument: newInvoiceDocument[0].file }),
            },
          },
        })
          .then(() => {
            refetchInvoices();
            closeModal();
            toast.success("Success", { description: "Invoice has been updated" });
          })
          .catch((err: Error | any) => {
            toast.error("Request failed", { description: err.message });
          });
      } catch (err: Error | any) {
        toast.error("Request failed", { description: "We were unable to upload the invoice file. Please try again" });
        closeModal();
      }
    }
  };

  const handleDeliverableSelection = (deliverableId: number) => {
    setSelectedDeliverables((prevSelectedDeliverables) => {
      if (!prevSelectedDeliverables) {
        return [deliverableId];
      } else if (prevSelectedDeliverables.includes(deliverableId)) {
        return prevSelectedDeliverables.filter((id) => id !== deliverableId);
      } else {
        return [...prevSelectedDeliverables, deliverableId];
      }
    });
  };

  const deliverables = useMemo(() => {
    if (campaign && campaign.deliverables) {
      return campaign.deliverables.map((deliverable) => {
        if (deliverable.status === "COMPLETED") {
          return (
            <MonetSwitch
              label={deliverable.name}
              showInRow={true}
              showLabels={false}
              size="small"
              onClick={() => handleDeliverableSelection(deliverable.deliverableId)}
              checked={selectedDeliverables.includes(deliverable.deliverableId)}
            />
          );
        } else return null;
      });
    } else return null;
  }, [campaign, selectedDeliverables]);

  const invoiceStatusOptions = useMemo(() => {
    return USER_SELECTABLE_INVOICE_STATUSES.map((status) => ({
      value: status,
      label: enumToNiceString(status),
    }));
  }, []);

  const resetInvoiceDocument = () => {
    methods.setValue("invoiceDocument", undefined, { shouldValidate: true });
    setChangeInvoiceDocument(!changeInvoiceDocument);
  };

  useEffect(() => {
    if (selectedDeliverables) {
      methods.setValue("deliverables", selectedDeliverables, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [selectedDeliverables]);

  return (
    <TailwindDrawer
      title="Invoice"
      subtitle="Invoices provide a way to secure funding for your campaign. Once underwriting is successfully completed, you can access funds up to the invoice's value."
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col h-full justify-between">
          <div className="flex flex-col gap-2">
            <Controller
              control={methods.control}
              name="invoiceStatus"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Invoice status"
                  options={invoiceStatusOptions}
                  placeholder="Please select..."
                  error={methods.formState.errors.invoiceStatus?.message as string}
                />
              )}
            />
            <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300">Invoice document</span>
            {changeInvoiceDocument ? (
              <div className="flex flex-col sm:flex-row gap-2">
                <MonetFileInput
                  {...methods.register(`invoiceDocument.0.file`)}
                  disabled={methods.formState.isSubmitting}
                  error={methods.formState.errors.invoiceDocument?.[0]?.file?.message as string}
                />
                <MonetButton variant="outlined" size="small" onClick={() => resetInvoiceDocument()} className="h-[50px]">
                  Cancel
                </MonetButton>
              </div>
            ) : (
              <div className="flex flex-col sm:flex-row gap-2">
                <MonetBorderedCardContent className="w-full p-2 justify-center items-center h-[50px] block min-w-0">
                  <SignedUrl url={invoice.invoiceDocument} className="justify-center" />
                </MonetBorderedCardContent>
                <MonetButton variant="outlined" size="small" onClick={() => setChangeInvoiceDocument(!changeInvoiceDocument)} className="h-[50px]">
                  Change
                </MonetButton>
              </div>
            )}
            <MonetInput
              {...methods.register("invoiceNumber")}
              label="Invoice number"
              error={methods.formState.errors.invoiceNumber?.message as string}
              disabled={methods.formState.isSubmitting}
              id="invoiceNumber"
              tooltip="Invoice number that you use for reconciling"
            />
            <MonetMoneyInput currency={invoice.invoiceValue.currency} name="invoiceValue" label="Invoice value" />
            <Controller
              control={methods.control}
              name="dueDate"
              render={({ field: { ...inputProps } }) => (
                <MonetDatePicker
                  key="dueDate"
                  inputProps={inputProps}
                  disabled={methods.formState.isSubmitting}
                  label="Due date"
                  pickerOptions={{
                    minDate: dayjs().format("YYYY-MM-DD"),
                    mode: "single",
                  }}
                  error={methods.formState.errors.dueDate?.message as string}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="paymentTerms"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Payment terms"
                  placeholder="Please select payment terms..."
                  options={[
                    { label: "30 days", value: 30 },
                    { label: "45 days", value: 45 },
                    { label: "60 days", value: 60 },
                    { label: "90 days", value: 90 },
                  ]}
                  error={methods.formState.errors.paymentTerms?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
              )}
            />
            <div className="text-sm flex flex-col gap-1 mb-4">
              <p className="font-semibold">Deliverables</p>
              <p>Please select the campaign deliverables associated with this invoice. Once the client signs off on the deliverables, you can apply for financing</p>
              <MonetBorderedCardContent className="max-h-[150px] overflow-auto mt-2">{deliverables}</MonetBorderedCardContent>
              <p className="text-sm text-red-500 mt-2">{methods.formState.errors.deliverables?.message as string}</p>
            </div>
          </div>
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isDirty || !methods.formState.isValid}
            loading={methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default UpdateInvoiceDrawer;
