import { Box, FormControl, TextField as MuiTextField, Stack, SxProps, Theme, Typography, styled, useTheme } from "@mui/material";
import { Radios, TextField } from "mui-rff";
import React, { useMemo } from "react";
import { useFormState } from "react-final-form";
import AgreementTermsUploadField from "views/partners/components/AgreementTermsUploadField";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

type TermsAndConditionsProps = {
  name: string;
  title: string;
};
const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ name, title }) => {
  const { values } = useFormState();

  const theme = useTheme();

  const termsType = values.defaultConfiguration[name]?.linkType || "Partner";

  const radioGroupStyles = useMemo<SxProps<Theme>>(
    () => ({
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },

      "& .MuiFormControlLabel-root": {
        border: `1px solid ${theme.palette.primary.main}`,
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
        marginBottom: theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        [theme.breakpoints.up("md")]: {
          marginRight: 0,
          "&:not(:last-child)": {
            marginRight: theme.spacing(1),
          },
          marginBottom: 0,
        },
      },
    }),
    [theme],
  );
  const fieldName = `defaultConfiguration.${name}.link`;
  const linkTypeName = `defaultConfiguration.${name}.linkType`;
  const isInvoiceTerms = useMemo(() => name == "invoiceAssignmentTerms", [name]);
  return (
    <Box>
      <StyledTypography>{title}</StyledTypography>
      <Stack direction="column" spacing={1} justifyContent="space-between">
        <Radios
          formControlProps={{
            sx: {
              width: "100%",
            },
          }}
          radioGroupProps={{
            row: true,
            sx: radioGroupStyles,
          }}
          data={[
            { label: "Partner Specific", value: "Partner" },
            { label: "Generic T&C's", value: "Generic" },
          ]}
          name={linkTypeName}
        />

        <FormControl fullWidth>
          <Box width="100%" display={termsType == "Generic" ? "block" : "none"}>
            <MuiTextField fullWidth disabled sx={{ "& input": { textAlign: "center" } }} placeholder="GENERIC TERMS & CONDITIONS" />
          </Box>

          {!isInvoiceTerms && (
            <Box width="100%" display={termsType == "Partner" ? "block" : "none"}>
              <TextField fullWidth data-testid={`editPartnerJourney-${name}-partnerSpecificField`} name={fieldName} placeholder="Enter a valid url" autoComplete="off" />
            </Box>
          )}
        </FormControl>
      </Stack>

      {termsType === "Partner" && isInvoiceTerms && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <AgreementTermsUploadField isNew={false} name="onboardingAgreement" title="Onboarding agreement" />
          <AgreementTermsUploadField isNew={false} name="standardTerms" title="Standard terms" />
          <AgreementTermsUploadField isNew={false} name="earlyPayAgreement" title="EarlyPay agreement" />
        </FormControl>
      )}
    </Box>
  );
};

export default TermsAndConditions;
