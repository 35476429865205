import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PropsWithChildren, useMemo, useState } from "react";
import TailwindSelectInput from "./TailwindSelectInput";

type TailwindTabGroupProps = PropsWithChildren & {
  tabs: {
    label: string;
    content: React.ReactElement;
    isVisible: boolean;
  }[];
};

const TailwindTabGroup: React.FC<TailwindTabGroupProps> = ({ tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (tab: number) => {
    setCurrentTab(tab);
  };

  const visibleTabs = useMemo(() => {
    return tabs.filter((tab) => tab.isVisible);
  }, [tabs]);

  const selectMenuOptions = useMemo(() => {
    return visibleTabs.map((tab, index) => ({
      label: tab.label,
      value: index,
    }));
  }, [visibleTabs]);

  return (
    <TabGroup className="flex flex-col gap-4 overflow-hidden" selectedIndex={currentTab} onChange={setCurrentTab}>
      <TabList className="hidden sm:flex space-x-1">
        {visibleTabs.map((tab) => (
          <Tab
            key={tab.label}
            className="data-[selected]:bg-gray-100 data-[selected]:text-gray-800 data-[selected]:hover:text-gray-800 dark:data-[selected]:bg-neutral-700 dark:data-[selected]:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-500 dark:hover:text-neutral-400 focus:outline-none"
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <div className="block sm:hidden">
        <TailwindSelectInput
          inputProps={{
            name: "tabMenuMobile",
            onChange: handleChange,
            value: selectMenuOptions[currentTab].value,
          }}
          options={selectMenuOptions}
        />
      </div>
      <TabPanels>
        {visibleTabs.map((tab) => (
          <TabPanel key={`${tab.label}-content`}>{tab.content}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};

export default TailwindTabGroup;
