import { ReactComponent as MenuIcon } from "assets/tailwind/icons/menu.svg";
import { ReactComponent as MonetLogo } from "assets/tailwind/monet-logo.svg";
import AccountDropdown from "components/Impersonate/Impersonate";
import useAppProvider from "contexts/AppProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { DASHBOARD_PATH } from "routing/paths";
import MonetButton from "../MonetButton";
import DesktopNavigation from "./Navigation/DesktopNavigation";
import MobileNavigation from "./Navigation/MobileNavigation";

type ViewLayoutProps = PropsWithChildren & {
  helmet: string;
};

const ViewLayout: React.FC<ViewLayoutProps> = ({ children, helmet }) => {
  const { user } = useSessionProvider();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isMobile } = useAppProvider();

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    if (!isMobile && isMobileMenuOpen) {
      toggleMobileMenu();
    }
  }, [isMobile]);

  const impersonationWarning = useMemo(() => {
    if (user.isImpersonating) {
      return (
        <div className="bg-red-100 border border-red-800 rounded-lg flex items-center px-1.5 py-1 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500">
          <span>
            Impersonating: <span className="font-semibold">{user.partner}</span>
          </span>
        </div>
      );
    } else return null;
  }, [user.isImpersonating]);

  return (
    <>
      <Helmet>
        <title>{helmet} - MONET</title>
      </Helmet>
      {/* Header */}
      <header className="lg:ms-[259px] sticky top-0 inset-x-0 z-[1] bg-[#f8fafc] border-b border-gray-200 dark:bg-neutral-900 dark:border-neutral-700">
        <div className="flex justify-between gap-3 flex-wrap lg:justify-end basis-full items-center w-full py-2 px-5 sm:px2.5" aria-label="Global">
          <div className="lg:hidden text-lg font-semibold text-monet-blue dark:text-white">
            <Link to={DASHBOARD_PATH}>
              <MonetLogo width={100} />
            </Link>
          </div>
          <div className="flex gap-3 flex-wrap">
            {impersonationWarning}
            <div className="border-e border-gray-200 w-px mx-3 dark:border-neutral-700"></div>
            <AccountDropdown />
            <MonetButton variant="white" size="x-small" className="lg:hidden" onClick={toggleMobileMenu}>
              <MenuIcon />
            </MonetButton>
          </div>
        </div>
      </header>
      <DesktopNavigation />
      <MobileNavigation open={isMobileMenuOpen} toggle={toggleMobileMenu} />
      <main id="content" className="lg:ps-[259px] min-h-[calc(100vh-51px)] flex bg-[#fcfdfd] dark:bg-neutral-700">
        <div className="py-3 px-3 sm:py-5 sm:px-7 min-h-full flex flex-col flex-1 w-full gap-6">{children}</div>
      </main>
      {/* End main content */}
    </>
  );
};

export default ViewLayout;
