import { Money, MoneyStorage } from "@monet-money/money-type";
import dayjs from "dayjs";
import { ConfigurationInput, ContactInput, OnboardingUpdatePartnerProfileInput, PartnerBusinessMetadataInput } from "generated/ep3.graphql.types";
import { validatePhoneNumber } from "shared/utils/phone";
import { Invoice } from "types/Invoice";
import { PartnerCollectionAccount } from "types/Partner";
import { Payee } from "types/Payee";
import { SchemaOf, ValidationError, array, boolean, mixed, number, object, string } from "yup";
import { INVOICE_STATUSES, MAX_FILE_SIZE_IN_MB } from "../constants/Constants";
import {
  REGEX_3_CHARACTERS,
  REGEX_ADDRESS,
  REGEX_EMAIL_DOMAIN,
  REGEX_EMAIL_LOCAL,
  REGEX_EMAIL_TOP_LEVEL_DOMAIN,
  REGEX_FULL_NAME,
  REGEX_NAME_SPECIAL_CHARS,
  REGEX_NO_START_END_WHITESPACE,
  REGEX_POST_CODE,
  REGEX_URI_COMPLIANT_NO_WHITESPACE_START_OR_END,
  REGEX_URL,
} from "../constants/Regex";
import { validateBorderlessField, validateBorderlessString } from "./borderlessFieldLists";
import { removeCommas, splitCamelCaseString } from "./string";

type UserInput = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  email: string;
};

type CampaignDetails = {
  campaignName: string;
  brandPromoted: string;
  deliverables: string;
  clientName: string;
  clientCountryOfRegistration: string;
  contact: ContactInput;
};

type CampaignInvoice = {
  campaignContracts: string[];
  purchaseOrder?: string[];
  campaignValue: MoneyStorage;
  invoice: string[];
  clientSignOff?: string[];
  liveLinks?: string[];
  dueDate?: string;
  paymentTerms: number;
};

type PayeeWithoutId = Omit<Payee, "payeeId">;

export const getRequiredMessage = (message: string) => `${message} is required`;
export const getErrorMessage = (type: string) => (type === "required" ? "FIELD_MISSING" : "FIELD_VALIDATION_FAILED");

export const payeeSchema = <SchemaOf<PayeeWithoutId>>object()
  .shape({
    firstName: string().required(getRequiredMessage("First Name")),
    email: string().test("validate-email", "Please enter a valid email", (value, ctx) => {
      if (!value) return false;
      return isEmailValid(value);
    }),
    phoneNumber: string().required(getRequiredMessage("Phone Number")),
    country: string().strict().typeError(getRequiredMessage("Bank Country Code")).required(getRequiredMessage("Bank Country Code")),
    accountType: string().oneOf(["PERSONAL", "BUSINESS"]).required(getRequiredMessage("Transaction Type")),
    payeeAlias: string().matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid payee alias").required(getRequiredMessage("Payee Alias")),
  })
  .required(getRequiredMessage("Payee Details"))
  .test("payee-values-check", "payee details not found", (val: Payee) => {
    if (val) {
      const beneficiaryKeys = Object.keys(val);
      if (!beneficiaryKeys.length) {
        return false;
      }

      const keysWithErrors: ValidationError[] = [];

      for (const item of beneficiaryKeys) {
        const field = item;
        const fieldValue = val[item as keyof PayeeWithoutId];
        if (!fieldValue) {
          keysWithErrors.push(new ValidationError(`${splitCamelCaseString(item)} is required`, fieldValue, field));
        } else if (!validateBorderlessField(item, fieldValue, val.country)) {
          const fieldName = splitCamelCaseString(item);
          let message = `${fieldName} is invalid`;

          if (["GBR", "AUS"].includes(val.country) && item == "routingNumber") {
            message = "Sort Code is invalid";
          } else if (item == "accountNumber") {
            message = "Account number / IBAN does not match selected country format";
          }

          keysWithErrors.push(new ValidationError(message, fieldValue, field));
        }
      }

      if (keysWithErrors.length) {
        return new ValidationError(keysWithErrors);
      }
    }
    return true;
  })
  .default(undefined);

export const MoneySchema = <SchemaOf<MoneyStorage>>object({
  amount: number().typeError("Amount Must be a number").positive().min(1, "Amount must be greater than or equal to 1").required(getRequiredMessage("Amount")),
  currency: string().length(3).required(getRequiredMessage("Currency")),
}).required(getRequiredMessage("Money"));

export const baseContactSchema = {
  name: string().matches(REGEX_FULL_NAME, "Enter a valid name in the format first name [space] last name").typeError("Contact Name is required"),
  email: string().test("validate-email", "Please enter a valid email", (value, ctx) => {
    if (!value)
      return ctx.createError({
        type: "required",
        message: getRequiredMessage("email"),
        path: `contact.email`,
      });
    return isEmailValid(value);
  }),
  phoneNumber: string().test("validate-phone-number", "Enter a valid contact phone number", (value, ctx) => {
    if (!value) return true;
    try {
      return validatePhoneNumber(value!).isValid;
    } catch (error) {
      return ctx.createError({
        message: "Enter a valid contact phone number",
      });
    }
  }),
  jobTitle: string().matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid job title").typeError("Job Title is reqired"),
};

/**
 * Checks if an email address is valid.
 *
 * @param {string} email - The email address to be validated.
 * @returns {boolean} - Returns true if the email address is valid, false otherwise.
 */
export const isEmailValid = (email: string): boolean => {
  if (!email.includes("@")) {
    return false;
  }
  const [local, rootDomain] = email.split("@");

  if (!REGEX_EMAIL_LOCAL.test(local)) {
    return false;
  }

  if (!rootDomain.includes(".")) {
    return false;
  }

  const [domainName, topLevelDomain] = rootDomain.split(".");

  if (!REGEX_EMAIL_DOMAIN.test(domainName) || !REGEX_EMAIL_TOP_LEVEL_DOMAIN.test(topLevelDomain)) {
    return false;
  }

  return true;
};

// partner address schema
export const PartnerAddressSchema = object()
  .shape({
    addressLine1: string().matches(REGEX_ADDRESS, { message: "Enter a valid Address Line 1" }).required(getRequiredMessage("Address Line 1")),
    addressLine2: string().nullable(),
    city: string().matches(REGEX_ADDRESS, { message: "Enter a valid City" }).required(getRequiredMessage("City")),
    postcode: string()
      .matches(REGEX_POST_CODE, {
        message: "Please enter a valid post code",
      })
      .required(getRequiredMessage("Post Code")),
  })
  .required();

export const updatePartnerProfileValidationSchema = <SchemaOf<OnboardingUpdatePartnerProfileInput>>object().shape({
  name: string()
    .min(2)
    .matches(/^([A-Za-z\d])[A-Za-z\d\s-]+$/, "Enter a valid company name")
    .required("Company Name is a required field"),

  businessMetadata: object().shape({
    companyWebsite: string()
      .test("validate-website", (val, ctx) => {
        if (!val) return false;
        if (!val?.match(REGEX_URL)) {
          return ctx.createError({
            type: "required",
            message: "Enter a valid website",
            path: "businessMetadata.companyWebsite",
          });
        }

        return true;
      })
      .required("Website is a required field"),
    countryOfRegistration: string().typeError(getRequiredMessage("Country")).required("Country is a required field"),
    functionalCurrencies: array().min(1, "Select at least one (1) currency"),
  }),
  address: PartnerAddressSchema,
  logo: object().shape({
    file: string().required("Logo is required"),
    type: string().required("Logo is required"),
    name: string().required("Logo is required"),
  }),
});

export const deliverablesSchema = string().test("length", (value, ctx) => {
  if (!value) {
    return ctx.createError({
      message: getRequiredMessage("Deliverables"),
      path: `deliverables`,
    });
  }
  if (value && value.length < 3) {
    return ctx.createError({
      message: "Deliverables must be 3 characters or more",
      path: `deliverables`,
    });
  }
  return true;
});

export const liveLinksSchema = array()
  .of(
    string().test("link-test", "Please enter a valid URL", (value) => {
      if (!value) return true;

      if (!value.match(REGEX_URL)) return false;

      return true;
    }),
  )
  .optional();

export const getMinimumErrorMessage = (field: string, length: number) => `${field} must be at least ${length} characters`;

export const contactSchema = object()
  .shape({
    ...baseContactSchema,
    name: baseContactSchema.name.required(getRequiredMessage("Contact Name")),
    email: baseContactSchema.email.required(getRequiredMessage("Contact Email")),
    phoneNumber: baseContactSchema.phoneNumber.notRequired(),
    jobTitle: string().matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid job title").required(getRequiredMessage("Job Title")).typeError("Job Title is reqired"),
  })
  .default(undefined)
  .required(getRequiredMessage("Contact"));

export const campaignDetailsSchema = <SchemaOf<CampaignDetails>>object().shape({
  campaignName: string().required(getRequiredMessage("Campaign Name")).typeError("Campaign Name is required").min(3, getMinimumErrorMessage("Campaign Name", 3)),
  brandPromoted: string().required(getRequiredMessage("Brand Promoted")).typeError("Brand Promoted is required").min(3, getMinimumErrorMessage("Brand Promoted", 3)),
  deliverables: deliverablesSchema,
  clientName: string().required(getRequiredMessage("Client Name")).min(3, getMinimumErrorMessage("Client Name", 3)),
  clientCountryOfRegistration: string().typeError("Client country of registration is required").matches(REGEX_3_CHARACTERS).required(),
  contact: contactSchema,
});

export const campaignInvoiceSchema = <SchemaOf<CampaignInvoice>>object().shape({
  campaignContracts: array().required(getRequiredMessage("Campaign Contracts")),
  purchaseOrder: array().optional(),
  campaignValue: MoneySchema,
  invoice: array().required(getRequiredMessage("Invoice")),
  clientSignOff: array().when("campaignType", {
    is: "STANDARD_CAMPAIGN",
    then: array().test("has-files", "At least one file is required", function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        return true;
      } else {
        return createError({
          path,
          message: "At least one file is required",
        });
      }
    }),
    otherwise: array(),
  }),
  liveLinks: liveLinksSchema,
  dueDate: string()
    .when("campaignType", {
      is: "STANDARD_CAMPAIGN",
      then: string()
        .required(getRequiredMessage("Due Date"))
        .min(8, getMinimumErrorMessage("Due Date", 8))
        .test("min-date", "Due date must be in the future", (value) => {
          return dayjs(value).isAfter(dayjs());
        }),
      otherwise: string().notRequired(),
    })

    .typeError(getRequiredMessage("Due Date")),

  paymentTerms: number().required(getRequiredMessage("Payment Terms")).typeError("Payment Terms is reqired"),
});

export const updatePartnerBusinessMetadataSchema: SchemaOf<PartnerBusinessMetadataInput> = object().shape({
  name: string()
    .min(2, "Must be at least 2 characters")
    .matches(/^([A-Za-z\d])[A-Za-z\d\s-]+$/, "Enter a valid company name")
    .required("Company Name is a required field"),
  countryOfRegistration: string().required(getRequiredMessage("Country of registration")).typeError("Country of registration is required"),
  address: PartnerAddressSchema,
  industry: string().required(getRequiredMessage("Industry")).typeError("Industry is required"),
  turnover: string().required(getRequiredMessage("Turnover")).typeError("Turnover is required"),
  functionalCurrencies: array().of(string().required()).required(getRequiredMessage("Transaction currencies")),
  companyWebsite: string()
    .test("validate-website", (val, ctx) => {
      if (!val) return false;
      if (!val?.match(REGEX_URL)) {
        return ctx.createError({
          type: "required",
          message: "Enter a valid website",
          path: "companyWebsite",
        });
      }

      return true;
    })
    .required("Website is a required field"),
});

const validImageExtensions: string[] = ["png", "jpg", "jpeg"];

function isValidFileType(value: any) {
  if (value) {
    return validImageExtensions.includes(value.name.split(".").pop()!);
  } else return false;
}

export const organisationLogoUploadSchema = object().shape({
  logo: mixed()
    .required("Logo is required")
    .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validImageExtensions.join(", ")}`, (value) => isValidFileType(value)),
});

export const updateConfigurationSchema: SchemaOf<ConfigurationInput> = object().shape({
  configurationId: string().required("Configuration Id is missing"),
  payoutTtlDelta: number()
    .positive()
    .min(1, "Payout Expiry must be greater than or equal to 1")
    .required(getRequiredMessage("Payout Expiry"))
    .typeError(getRequiredMessage("Payout Expiry")),
  showLanding: boolean().required(getRequiredMessage("Show Landing Page")).typeError(getRequiredMessage("Show Landing Page")),
  returnUrl: string().required("Please enter a return URL").matches(REGEX_URL, "Please enter a valid return URL"),
  vendorTermsAndConditions: string()
    .test("terms-and-conditions-test", "Please enter a valid T&Cs URL", (val) => {
      if (val === "Generic") return true;
      if (val && !val.match(REGEX_URL)) return false;
      return true;
    })
    .required("Please enter a valid T&Cs URL"),
});

export const AddCollectionAccountSchema: SchemaOf<PartnerCollectionAccount> = object().shape({
  bankName: string().matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid bank name").required(getRequiredMessage("Bank name")),
  accountNumber: string()
    .test("account-number", "Enter a valid account number", (val) => {
      if (!val) return false;
      return validateBorderlessString("accountNumber", val, "GBR");
    })
    .required(getRequiredMessage("Account number")),
  accountName: string().matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid account name").required(getRequiredMessage("Account name")),
  currency: string().matches(REGEX_3_CHARACTERS, "Enter a valid currency name").required(getRequiredMessage("Currency")),
  iban: string()
    .test("iban", "Enter a valid iban", (val) => {
      if (!val) return false;
      return validateBorderlessString("accountNumber", val, "GBR", true);
    })
    .required(getRequiredMessage("Iban")),
  sortCode: string()
    .test("sort-code", "Enter a valid sort code", (val) => {
      if (!val) return false;
      return validateBorderlessString("routingNumber", val, "GBR");
    })
    .required(getRequiredMessage("Sort code")),
  swiftCode: string()
    .test("swift-code", "Enter a valid swift code", (val) => {
      if (!val) return false;
      return validateBorderlessString("bankSwiftBic", val);
    })
    .required(getRequiredMessage("Swift code")),
});

export type UpdateCampaignDetailsFormObject = {
  campaignName?: string;
  brandPromoted?: string;
  campaignValue?: string;
  brief?: string;
  status?: string;
};

export const updateCampaignDetailsSchema = <SchemaOf<UpdateCampaignDetailsFormObject>>object().shape({
  status: string().required("Please select a status for this campaign"),
  campaignName: string().required("Please enter a campaign name").matches(REGEX_URI_COMPLIANT_NO_WHITESPACE_START_OR_END, "Please enter a valid campaign name"),
  brandPromoted: string().required("Please enter the promoted brand").matches(REGEX_NAME_SPECIAL_CHARS, "Please enter a valid brand name"),
  campaignValue: string()
    .required("Please enter a budget limit")
    .test("valid-value", "test", (value) => {
      if (value) {
        const moneyValue = new Money(removeCommas(value) as string);

        if (moneyValue.amount <= 0) {
          return false;
        } else return true;
      }
      return false;
    }),
  brief: string().optional(),
});

export type CreateCampaignFormObject = {
  campaignName: string;
  campaignValueAmount: string;
  campaignValueCurrency: string;
  brandPromoted: string;
  brief: string;
  clientLegalName: string;
  clientCountryOfRegistration: string;
  invoiceAssignmentTerms: boolean;
};

export const createCampaignSchema = <SchemaOf<CreateCampaignFormObject>>object().shape({
  campaignName: string().required("Please enter a campaign name").matches(REGEX_URI_COMPLIANT_NO_WHITESPACE_START_OR_END, "Please enter a valid campaign name"),
  campaignValueAmount: string()
    .required("Please enter the campaign value")
    .test("test-amount", "Please enter a valid campaign value", (val) => {
      if (val) {
        if (Number(removeCommas(val)) <= 0) {
          return false;
        } else return true;
      } else return false;
    }),
  campaignValueCurrency: string().required("Please select a currency for the campaign value"),
  brandPromoted: string().required("Please enter the brand being promoted").min(3, "Brand promoted must be at least 3 characters long"),
  brief: string().optional(),
  clientCountryOfRegistration: string().required("Please enter the client country of registration"),
  clientLegalName: string().required("Please enter the client legal name").matches(REGEX_URI_COMPLIANT_NO_WHITESPACE_START_OR_END, "Please enter a valid client legal name"),
  invoiceAssignmentTerms: boolean().oneOf([true], "Please agree to the invoice assignment"),
});

export type UpdateClientFormObject = {
  legalName: string;
  countryOfRegistration: string;
  email: string;
  jobTitle: string;
  name: string;
  phoneNumber: string;
};

export const updateClientSchema = <SchemaOf<UpdateClientFormObject>>object().shape({
  legalName: string().required("Please enter the client legal name").matches(REGEX_URI_COMPLIANT_NO_WHITESPACE_START_OR_END, "Please enter a valid client legal name"),
  countryOfRegistration: string().required("Please enter the client country of registration"),
  email: string().test("validate-email", "Please enter a valid client contact email address", (value, ctx) => {
    if (!value)
      return ctx.createError({
        type: "required",
        message: "Please enter a client contact email address",
        path: `email`,
      });
    if (/\s/.test(value)) {
      return false;
    }
    return isEmailValid(value);
  }),
  phoneNumber: string().test("validate-phone-number", "Please enter a valid client contact phone number", (value) => {
    if (value) {
      try {
        return validatePhoneNumber(value).isValid;
      } catch (error) {
        return false;
      }
    } else return true;
  }),
  jobTitle: string().test("validate-job-title", "Please enter a valid client contact job title", (value) => {
    if (value) {
      if (value.match(REGEX_NAME_SPECIAL_CHARS)) {
        return true;
      } else return false;
    } else return true;
  }),
  name: string().matches(REGEX_FULL_NAME, "Enter a valid name in the format first name [space] last name").typeError("Contact Name is required"),
});

export type AddDeliverablesFormObject = {
  name: string;
  description?: string;
  liveLinks?: string[];
  status: boolean;
};

export const updateDeliverablesSchema = <SchemaOf<AddDeliverablesFormObject>>object().shape({
  name: string().required("Please enter a name").matches(REGEX_NO_START_END_WHITESPACE, "Please enter a valid name"),
  description: string().optional(),
  status: boolean().required("Please state whether or not that this deliverable is completed"),
  liveLinks: array()
    .of(
      string().test("link-test", "Please enter a valid URL", (value) => {
        if (!value) return true;

        if (!value.match(REGEX_URL)) return false;

        return true;
      }),
    )
    .optional(),
});

export type CreateInvoiceFormObject = {
  invoiceDocument: [
    {
      file: any;
    },
  ];
  invoiceNumber: string;
  invoiceValue: string;
  dueDate: Date[];
  deliverables: number[];
  termsAndConditions: boolean;
  invoiceStatus: string;
  paymentTerms: number;
};

const validInvoiceExtensions: string[] = ["png", "jpg", "jpeg", "pdf", "docx", "doc"];

function isValidInvoiceFileType(value: any) {
  if (value && typeof value === "object") {
    return validInvoiceExtensions.includes(value.name.split(".").pop()!);
  } else return false;
}

export const createInvoiceSchema = <SchemaOf<CreateInvoiceFormObject>>object().shape({
  invoiceStatus: string().required(),
  invoiceDocument: array().of(
    object().shape({
      file: mixed()
        .required("Please select a file to upload")
        .test("is-required", "Please select a file to upload", (value) => {
          if (!value || value.length === 0) {
            return false;
          }
          return true;
        })
        .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validInvoiceExtensions.join(", ")}`, (value) => isValidInvoiceFileType(value[0]))
        .test("is-valid-size", "File to large. Maximum file size is 5MB", (value) => value.length > 0 && value[0].size <= MAX_FILE_SIZE_IN_MB),
    }),
  ),
  paymentTerms: number().required("Please select the payment terms for this campaign"),
  invoiceNumber: string().required("Please enter a invoice number"),
  invoiceValue: string()
    .required("Please enter an invoice value")
    .test("valid-value", "Please enter a valid invoice value", (value) => {
      if (value) {
        const moneyValue = new Money(removeCommas(value) as string);

        if (moneyValue.amount <= 0) {
          return false;
        } else return true;
      }
      return false;
    }),
  dueDate: array()
    .test("date-test", "Please enter an invoice due date", (value) => {
      if (!value || value.length < 0) return false;

      if (!dayjs(value[0]).isValid()) return false;

      return true;
    })
    .required("Please enter an invoice due date")
    .nullable(),
  deliverables: array()
    .of(string().required("Please select a deliverable"))
    .test("min-test", "Please select at least one deliverable associated with this invoice", (value) => {
      if (!value || value.length <= 0) return false;
      return true;
    })
    .default(undefined),
  termsAndConditions: boolean().required("The terms and conditions must be accepted").oneOf([true], "The terms and conditions must be accepted"),
});

type createInvoiceServerObject = Omit<CreateInvoiceFormObject, "dueDate" | "invoiceDocument"> & {
  dueDate: string;
  invoiceDocument: string;
};

export const createInvoiceServerSchema = <SchemaOf<createInvoiceServerObject>>object().shape({
  invoiceStatus: string().required(),
  invoiceDocument: string().required("Invoice documents is required"),
  invoiceValue: string()
    .required("Please enter an invoice value")
    .test("valid-value", "Please enter a valid invoice value", (value) => {
      if (value) {
        const moneyValue = new Money(removeCommas(value) as string);

        if (moneyValue.amount <= 0) {
          return false;
        } else return true;
      }
      return false;
    }),
  invoiceNumber: string().required("Please enter a invoice number"),
  paymentTerms: number().required("Please select the payment terms for this campaign"),
  dueDate: string()
    .test("date-test", "Please enter an invoice due date", (value) => {
      if (!value) return false;

      if (!dayjs(value).isValid() || !dayjs().isBefore(value)) return false;

      return true;
    })
    .required("Please enter an invoice due date")
    .nullable(),
  deliverables: array()
    .of(number().required("Please select a deliverable"))
    .test("min-test", "Please select at least one deliverable associated with this invoice", (value) => {
      if (!value || value.length <= 0) return false;
      return true;
    })
    .default(undefined),
  termsAndConditions: boolean().required("The terms and conditions must be accepted").oneOf([true], "The terms and conditions must be accepted"),
});

export type UpdateInvoiceFormObject = Omit<CreateInvoiceFormObject, "termsAndConditions" | "invoiceDocument"> & {
  invoiceStatus: string;
  invoiceDocument?: [
    {
      file: any;
    },
  ];
};

export const updateInvoiceSchema = <SchemaOf<UpdateInvoiceFormObject>>object().shape({
  invoiceStatus: string()
    .required("Please select an invoice status")
    .test("is-valid-status", "Please select an invoice status", (value) => {
      if (!value) return false;
      if (!INVOICE_STATUSES.includes(value as Invoice["invoiceStatus"])) return false;
      return true;
    }),
  invoiceDocument: array()
    .of(
      object()
        .shape({
          file: mixed()
            .test("is-required", "Please select a file to upload", (value) => {
              if (value && value.length === 0) {
                return false;
              }
              return true;
            })
            .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validInvoiceExtensions.join(", ")}`, (value) => isValidInvoiceFileType(value[0]))
            .test("is-valid-size", "File to large. Maximum file size is 5MB", (value) => value.length > 0 && value[0].size <= MAX_FILE_SIZE_IN_MB)
            .notRequired(),
        })
        .notRequired(),
    )
    .notRequired(),
  invoiceNumber: string().required("Please enter a invoice number"),
  paymentTerms: number().required("Please select the payment terms for this campaign"),
  invoiceValue: string()
    .required("Please enter an invoice value")
    .test("valid-value", "Please enter a valid invoice value", (value) => {
      if (value) {
        const moneyValue = new Money(removeCommas(value) as string);

        if (moneyValue.amount <= 0) {
          return false;
        } else return true;
      }
      return false;
    }),
  dueDate: array()
    .test("date-test", "Please enter an invoice due date", (value) => {
      if (!value || value.length < 0) return false;

      if (!dayjs(value[0]).isValid()) return false;

      return true;
    })
    .required("Please enter an invoice due date")
    .nullable(),
  deliverables: array()
    .of(string().required("Please select a deliverable"))
    .test("min-test", "Please select at least one deliverable associated with this invoice", (value) => {
      if (!value || value.length <= 0) return false;
      return true;
    })
    .default(undefined),
});

type updateInvoiceServerObject = Omit<UpdateInvoiceFormObject, "dueDate" | "invoiceDocument"> & {
  dueDate: string;
  invoiceDocument: string;
};

export const updateInvoiceServerSchema = <SchemaOf<updateInvoiceServerObject>>object().shape({
  invoiceStatus: string()
    .required("Please select an invoice status")
    .test("is-valid-status", "Please select an invoice status", (value) => {
      if (!value) return false;
      if (!INVOICE_STATUSES.includes(value as Invoice["invoiceStatus"])) return false;
      return true;
    }),
  invoiceDocument: string().notRequired(),
  paymentTerms: number().required("Please select the payment terms for this campaign"),
  invoiceValue: string()
    .required("Please enter an invoice value")
    .test("valid-value", "Please enter a valid invoice value", (value) => {
      if (value) {
        const moneyValue = new Money(removeCommas(value) as string);

        if (moneyValue.amount <= 0) {
          return false;
        } else return true;
      }
      return false;
    }),
  invoiceNumber: string().required("Please enter a invoice number"),
  dueDate: string()
    .test("date-test", "Please enter an invoice due date", (value) => {
      if (!value) return false;

      if (!dayjs(value).isValid() || !dayjs().isBefore(value)) return false;

      return true;
    })
    .required("Please enter an invoice due date")
    .nullable(),
  deliverables: array()
    .of(string().required("Please select a deliverable"))
    .test("min-test", "Please select at least one deliverable associated with this invoice", (value) => {
      if (!value || value.length <= 0) return false;
      return true;
    })
    .default(undefined),
});

export type UnderwriteInvoiceFormObject = {
  approve: boolean;
  advanceRate: string;
  underwritingNotes: string;
  wiserfundingRiskScore?: string;
  experianRiskScore?: string;
  notes?: string;
};

export const underwriteInvoiceSchema = <SchemaOf<UnderwriteInvoiceFormObject>>object().shape({
  approve: boolean().required("Please select whether this request has been approved or rejected"),
  advanceRate: string().when("approve", {
    is: true,
    then: string()
      .required("Please enter a principal percentage")
      .test("valid-number", "Please enter a valid percentage", (value) => {
        if (!value) return false;
        if (value && isNaN(parseFloat(value))) {
          return false;
        }
        return true;
      })
      .test("max-value", "Maximum advance rate is 100%", (value) => {
        if (value && parseFloat(value) > 100) {
          return false;
        }
        return true;
      }),
    otherwise: string().notRequired(),
  }),
  notes: string().when("approve", {
    is: false,
    then: string().required("Please enter notes on why this has been rejected"),
    otherwise: string().notRequired(),
  }),
  wiserfundingRiskScore: string().notRequired(),
  experianRiskScore: string().notRequired(),
  underwritingNotes: string().required("Please enter underwriting notes"),
});
