import { Currency, Money, MoneyStorage } from "@monet-money/money-type";
import ErrorView from "components/ErrorView/ErrorView";
import MonetMetric from "components/tailwind/MonetMetric";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Partner } from "shared/types/Partner";
import { isInPartnerContext } from "utils/User";
import { useGetPartnersQuery } from "../graphql/queries.generated";
import PartnersTable from "./PartnersTable";

const ListPartners: React.FC = () => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [aggregations, setAggregations] = useState({
    verifiedPartners: 0,
    unVerifiedPartners: 0,
    totalCreditLimit: new Money(0, Currency.GBP),
    totalPartners: 0,
  });

  const { user } = useSessionProvider();
  const { data: partnersData, error: partnersError, loading: isLoadingPartners } = useGetPartnersQuery({ fetchPolicy: "network-only", skip: !user.isAdministrator });

  const navigate = useNavigate();

  useEffect(() => {
    if (partnersData) {
      const parsedPartners = JSON.parse(partnersData.getPartners) as Partner[];

      const totals = {
        verifiedPartners: 0,
        unVerifiedPartners: 0,
        totalCreditLimit: new Money(0, Currency.GBP),
        totalPartners: 0,
      };

      parsedPartners.forEach((partner: Partner) => {
        if (partner.kybStatus === "VERIFIED") {
          totals.verifiedPartners++;
        }
        if (partner.kybStatus === "NOT_VERIFIED") {
          totals.unVerifiedPartners++;
        }
        if (partner.limits) {
          totals.totalCreditLimit = totals.totalCreditLimit.add(Money.fromStorageType(partner.limits?.global as MoneyStorage).amount);
        }

        totals.totalPartners++;
      });

      setAggregations(totals);
      setPartners(parsedPartners);
    }
  }, [partnersData]);

  if (partnersError) {
    return <ErrorView title="Error loading partners" error={partnersError} />;
  }

  if (isInPartnerContext(user)) navigate("/403");

  return (
    <>
      <MonetViewTitle>Partners</MonetViewTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric
          label="Verified partners"
          value={aggregations.verifiedPartners}
          loading={isLoadingPartners}
          tooltip="Total number of verified partners. Partners that have passed KYB checks"
        />
        <MonetMetric
          label="Un-verified partners"
          value={aggregations.unVerifiedPartners}
          loading={isLoadingPartners}
          tooltip="Total number of un-verified partners. Partners that have not passed KYB checks"
        />
        <MonetMetric
          label="Total credit limit"
          value={aggregations.totalCreditLimit.format(true, true)}
          loading={isLoadingPartners}
          tooltip="The total sum of all verified partners global limit. This is a estimated value, no FX rate has been applied"
        />
        <MonetMetric label="Total partners" value={aggregations.totalPartners} loading={isLoadingPartners} tooltip="The total number of partners" />
      </div>
      <PartnersTable partners={partners} isLoading={isLoadingPartners} />
    </>
  );
};

export default ListPartners;
