import { Campaign } from "shared/types/Campaign";

export const getCampaignField = <T extends keyof Campaign>(campaignAttribute: T, campaignId: string, campaigns: Campaign[]): string | undefined => {
  const campaign = campaigns.find((campaign) => campaign.campaignId === campaignId);
  if (campaign) {
    return campaign[campaignAttribute] as string;
  }

  return undefined;
};
