import { Currency, Money } from "@monet-money/money-type";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDivider from "components/tailwind/MonetDivider";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetCheckbox from "components/tailwind/form/MonetCheckbox";
import usePayoutProvider from "contexts/PayoutProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Payout } from "shared/types/Payout";
import { removeCommas } from "shared/utils/string";

type PayoutSummaryStepProps = {
  payoutType: Payout["payoutType"];
};

const PayoutSummaryStep: React.FC<PayoutSummaryStepProps> = ({ payoutType }) => {
  const { selectedCampaign, selectedPayee, fundingFee, chargeCreator, partnerFee, customFee, setTotalPayout } = usePayoutProvider();
  const { partner } = useSessionProvider();

  const { register, watch } = useFormContext();

  const invoiceAmount = watch("payoutRequest");

  const invoiceCurrency = useMemo(() => {
    return selectedCampaign?.campaignValue.currency as Currency;
  }, [selectedCampaign]);

  const invoiceValueMoney = useMemo(() => {
    if (invoiceAmount && invoiceCurrency && !isNaN(Number(removeCommas(invoiceAmount)))) {
      return new Money(removeCommas(invoiceAmount)!, invoiceCurrency);
    } else return undefined;
  }, [invoiceAmount, invoiceCurrency]);

  const agencyFeeMoney = useMemo(() => {
    if (partnerFee && invoiceValueMoney) {
      return invoiceValueMoney.multiply(partnerFee.percentage);
    } else return undefined;
  }, [invoiceValueMoney, partnerFee]);

  const totalPayoutMoney = useMemo(() => {
    if (invoiceValueMoney) {
      let total = invoiceValueMoney;
      if (chargeCreator) {
        total = total.subtract(agencyFeeMoney || 0).subtract(fundingFee || 0);
      }
      setTotalPayout(total);
      return total;
    } else return undefined;
  }, [invoiceValueMoney, agencyFeeMoney, fundingFee, chargeCreator]);

  return (
    <div className="flex flex-col gap-4">
      <p>Payout summary</p>
      <MonetBorderedCardContent>
        <MonetLabelledItem label="Campaign" value={selectedCampaign?.campaignName} />
        <MonetLabelledItem label={payoutType === "VENDOR" ? "Vendor" : "Bank account"} value={selectedPayee?.payeeAlias} />
      </MonetBorderedCardContent>
      <MonetBorderedCardContent>
        <MonetLabelledItem label="Payout request" value={invoiceValueMoney?.format(true, true)} />
        <MonetLabelledItem label="Monet fee" value={fundingFee?.format(true, true)} />
        {payoutType === "VENDOR" && (
          <>
            <MonetLabelledItem label="Additional agency fee" value={agencyFeeMoney?.format(true, true)} />
            <MonetLabelledItem label="Pass Monet fee onto vendor" value={chargeCreator ? "Yes" : "No"} />
          </>
        )}
        <MonetDivider />
        <MonetLabelledItem label="Total payout" value={totalPayoutMoney?.format(true, true)} />
        {chargeCreator && customFee && <MonetLabelledItem label={`Credit to ${partner?.name}`} value={agencyFeeMoney?.format(true, true)} />}
        {!chargeCreator && <MonetLabelledItem label={`Charge to ${partner?.name}`} value={fundingFee?.format(true, true)} />}
      </MonetBorderedCardContent>
      <MonetCheckbox {...register("confirmDetails")} id="confirmDetails" label="Payout confirmation" placement="end">
        I confirm that the above payout details are correct
      </MonetCheckbox>
    </div>
  );
};

export default PayoutSummaryStep;
