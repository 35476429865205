import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import NoDataCard from "components/tailwind/NoDataCard";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useState } from "react";
import { Campaign } from "shared/types/Campaign";
import { Invoice } from "shared/types/Invoice";
import { Payout } from "shared/types/Payout";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { useGetInvoicesByCampaignQuery } from "views/campaigns/graphql/queries.generated";
import AddInvoiceDrawer from "./AddInvoiceDrawer";
import InvoiceTable from "./InvoiceTable";

type InvoiceSectionProps = {
  campaign?: Campaign;
  payouts?: Payout[];
  refetchCampaign(): void;
  refetchPayouts(): void;
};

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ campaign, payouts, refetchCampaign, refetchPayouts }) => {
  const [invoices, setInvoices] = useState<Invoice[]>();

  const { user } = useSessionProvider();
  const { openModal } = useModal();

  const {
    data: campaignInvoices,
    loading: isInvoicesLoading,
    refetch: refetchInvoices,
  } = useGetInvoicesByCampaignQuery({
    fetchPolicy: "cache-first",
    variables: {
      campaignId: campaign?.campaignId as string,
      partnerId: isInAdminContext(user) ? campaign?.partnerId : undefined,
    },
  });

  useEffect(() => {
    if (campaignInvoices) {
      const invoices = JSON.parse(campaignInvoices.getInvoicesByCampaign) as Invoice[];
      if (invoices.length > 0) {
        setInvoices(invoices);
      } else {
        setInvoices(undefined);
      }
    }
  }, [campaignInvoices]);

  return (
    <>
      <MonetCard>
        <div className="flex justify-between">
          <MonetCardTitle>Invoices</MonetCardTitle>
          {isInPartnerContext(user) && invoices && campaign?.campaignStatus !== "COMPLETED" && (
            <MonetButton size="x-small" onClick={() => openModal(AddInvoiceDrawer, { campaign: campaign, refetchInvoices: refetchInvoices, refetchCampaign: refetchCampaign })}>
              Add invoice
            </MonetButton>
          )}
        </div>
        {!invoices && !isInvoicesLoading ? (
          <NoDataCard showAsUnstyledCard={true}>
            No invoices
            {isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
              <MonetButton
                variant="link"
                size="fit-content"
                className="inline underline pl-1"
                onClick={() => openModal(AddInvoiceDrawer, { campaign: campaign, refetchInvoices: refetchInvoices, refetchCampaign: refetchCampaign })}
              >
                Add
              </MonetButton>
            )}
          </NoDataCard>
        ) : (
          <InvoiceTable
            invoices={invoices ?? []}
            isLoading={isInvoicesLoading}
            refetchInvoices={refetchInvoices}
            campaign={campaign}
            refetchCampaign={refetchCampaign}
            refetchPayouts={refetchPayouts}
            payouts={payouts}
          />
        )}
      </MonetCard>
    </>
  );
};

export default InvoiceSection;
