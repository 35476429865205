import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import StatusBadge from "components/tailwind/StatusBadge";
import { useSessionProvider } from "contexts/SessionProvider";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DATETIME_STRING_FORMAT, PAYOUT_STATUSES, PAYOUT_TYPES_LIST } from "shared/constants/Constants";
import { getPayoutTotalDetails } from "shared/logic/payouts";
import { Payout } from "shared/types/Payout";
import { convertUnixTimestampToDate, formatDate } from "utils/date";
import { PAYOUTS_PATH, PAYOUT_DETAILS } from "views/payouts/PayoutPath";

type PayoutsTableProps = {
  isLoading: boolean;
  payouts: Payout[];
};

const PayoutsTable: React.FC<PayoutsTableProps> = ({ isLoading, payouts }) => {
  const navigate = useNavigate();
  const { user } = useSessionProvider();

  const handleRowClick = (params: any) => {
    const path = `${PAYOUTS_PATH}${PAYOUT_DETAILS}?${createSearchParams({
      payoutId: params.row.payoutId as string,
    }).toString()}`;

    navigate(path);
  };

  const columns: GridColDef[] = [
    {
      field: "payoutStatus",
      headerName: "Status",
      type: "singleSelect",
      minWidth: 150,
      valueOptions: PAYOUT_STATUSES,
      renderCell: (params: GridRenderCellParams<Payout>) => {
        return <StatusBadge type="payout" text={params.row.payoutStatus} />;
      },
    },
    {
      field: "payoutType",
      type: "singleSelect",
      minWidth: 150,
      valueOptions: PAYOUT_TYPES_LIST,
      headerName: "Payout Type",
    },
    {
      field: "payeeFullName",
      minWidth: 200,
      flex: 1,
      headerName: "Payee name",
      valueGetter: (value, row) => `${row.payee.firstName} ${row.payee.lastName}`,
    },
    { field: "email", minWidth: 200, flex: 1, headerName: "Email", valueGetter: (value, row) => row.payee.email },
    {
      field: "payoutValue",
      headerName: "Payout value",
      type: "number",
      minWidth: 150,
      valueGetter: (value, row) => {
        return Number(Money.fromStorageType(value as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Payout>) => {
        return formatStorageType(params.row.payoutValue as MoneyStorage);
      },
    },
    {
      field: "feeSettings.earlyPayTotal",
      headerName: "EarlyPay total",
      type: "number",
      minWidth: 150,
      valueGetter: (value, row) => {
        return Number(Money.fromStorageType(row.feeSettings.earlyPayTotal as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Payout>) => {
        return formatStorageType(params.row.feeSettings.earlyPayTotal as MoneyStorage);
      },
    },
    {
      field: "feeSettings.monetFee",
      headerName: "Monet Fee",
      type: "number",
      minWidth: 150,
      valueGetter: (value, row) => {
        const calculatedFees = getPayoutTotalDetails(row as Payout);
        return Number(calculatedFees.monetFee.format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Payout>) => {
        const calculatedFees = getPayoutTotalDetails(params.row as Payout);
        return calculatedFees.monetFee.format();
      },
    },
    {
      field: "feeSettings.partnerFee",
      headerName: "Partner Fee",
      type: "number",
      minWidth: 150,
      valueGetter: (value, row) => {
        const calculatedFees = getPayoutTotalDetails(row as Payout);
        return Number(calculatedFees.partnerFee.format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Payout>) => {
        const calculatedFees = getPayoutTotalDetails(params.row as Payout);
        return calculatedFees.partnerFee.format();
      },
    },
    { field: "currency", minWidth: 100, flex: 1, headerName: "Currency", valueGetter: (value, row) => row.payoutValue.currency },
    { field: "daysFunded", minWidth: 100, flex: 1, headerName: "Days Funded", valueGetter: (value, row) => row.feeSettings.daysFunded },
    { field: "invoiceId", minWidth: 250, headerName: "Invoice ID" },
    { field: "payoutId", minWidth: 250, headerName: "Payout ID" },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      minWidth: 250,
      type: "dateTime",
      valueGetter: (value, row) => convertUnixTimestampToDate(row.ttl),
      renderCell: (params: GridRenderCellParams<Payout>) => {
        if (params.value) {
          return formatDate(params.row.ttl, DATETIME_STRING_FORMAT);
        } else {
          return "N/A";
        }
      },
    },
  ];

  return (
    <CustomDataGrid
      data-testid="campaign-payouts-table"
      hasFooter={true}
      rows={payouts}
      enableExport={false}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "createdAt",
              sort: "desc",
            },
          ],
        },
      }}
      showAsUnstyledCard={true}
      isDataLoading={isLoading}
      onRowClick={handleRowClick}
      getRowId={(row: Payout) => row.payoutId}
      sx={{
        height: 300,
      }}
    />
  );
};

export default PayoutsTable;
