import { ReactComponent as DownloadIcon } from "assets/tailwind/icons/download.svg";
import { ReactComponent as FileIcon } from "assets/tailwind/icons/file.svg";
import { ReactComponent as DeleteIcon } from "assets/tailwind/icons/trash-2.svg";
import { ReactComponent as UploadIcon } from "assets/tailwind/icons/upload.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import MonetMenuDropdown from "components/tailwind/MonetMenuDropdown";
import NoDataCard from "components/tailwind/NoDataCard";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { isInPartnerContext } from "utils/User";
import { getFileName, getSignedUrl } from "utils/file";
import { chainCaseToSentenceCase } from "utils/strings";
import DeleteDocumentModal from "./DeleteDocumentModal";
import DocumentUploadDrawer from "./DocumentUploadDrawer";

type DocumentSectionProps = {
  isLoading: boolean;
  refetchCampaign(): void;
  campaign?: Campaign;
};

const DocumentSection: React.FC<DocumentSectionProps> = ({ isLoading, campaign, refetchCampaign }) => {
  const { user } = useSessionProvider();
  const { openModal } = useModal();

  const handleDocumentDownload = async (fileName: string) => {
    await getSignedUrl(fileName)
      .then((signedUrl) => {
        window.open(signedUrl.url, "_blank");
      })
      .catch(() => {
        toast.error("Failed to download", { description: "Document failed to download. Please try again" });
      });
  };

  const renderDocuments = useMemo(() => {
    if (campaign?.documents && campaign.documents.length > 0) {
      return campaign?.documents.map((document, index) => (
        <div key={index} className="flex flex-row justify-between p-2 rounded-lg border border-grey-200 items-center gap-4 sm:gap-6">
          <div className="hidden sm:flex justify-center items-center bg-gray-200 p-2 rounded-lg">
            <FileIcon />
          </div>
          <div className="flex-1 text-sm">
            <p className="font-semibold">{getFileName(document.file)}</p>
            <p className="font-light">{chainCaseToSentenceCase(document.fileType)}</p>
          </div>
          <MonetMenuDropdown
            menuButtonProps={{
              variant: "white",
            }}
            footerChildren={
              isInPartnerContext(user) &&
              campaign?.campaignStatus !== "COMPLETED" && (
                <MonetDropdownMenuItem
                  variant="danger"
                  icon={<DeleteIcon />}
                  onClick={() => openModal(DeleteDocumentModal, { campaign: campaign, file: document.file, refetchCampaign: refetchCampaign })}
                >
                  Delete
                </MonetDropdownMenuItem>
              )
            }
          >
            <MonetDropdownMenuItem onClick={() => handleDocumentDownload(document.file)} icon={<DownloadIcon />}>
              Download
            </MonetDropdownMenuItem>
          </MonetMenuDropdown>
        </div>
      ));
    } else
      return isLoading ? (
        <MonetLoadingSpinner size="default" />
      ) : (
        <NoDataCard showAsUnstyledCard={true}>
          No documents
          {campaign && isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && (
            <MonetButton
              size="x-small"
              variant="link"
              className="w-fit underline"
              onClick={() => openModal(DocumentUploadDrawer, { campaign: campaign, refetchCampaign: refetchCampaign })}
            >
              Add
            </MonetButton>
          )}
        </NoDataCard>
      );
  }, [campaign, isLoading]);

  return (
    <MonetCard>
      <div>
        <div className="flex flex-row justify-between">
          <MonetCardTitle>Documents</MonetCardTitle>
          {isInPartnerContext(user) && campaign?.campaignStatus !== "COMPLETED" && campaign?.documents && campaign.documents.length > 0 && (
            <MonetButton onClick={() => openModal(DocumentUploadDrawer, { campaign: campaign, refetchCampaign: refetchCampaign })} size="x-small">
              <UploadIcon />
              Upload documents
            </MonetButton>
          )}
        </div>
      </div>
      {renderDocuments}
    </MonetCard>
  );
};

export default DocumentSection;
