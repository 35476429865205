import { REGEX_NAME_SPECIAL_CHARS } from "shared/constants/Regex";
import { validatePhoneNumber } from "shared/utils/phone";
import { isEmailValid } from "shared/utils/validators";
import { SchemaOf, object, string } from "yup";

export const userDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  position: "",
};

export type UserFormObject = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
};

export const userValidationSchema = <SchemaOf<UserFormObject>>object().shape({
  email: string().test("validate-email", "Please provide a valid email address", (value, ctx) => {
    if (!value)
      return ctx.createError({
        type: "required",
        message: "Email is a required field",
        path: `email`,
      });
    if (/\s/.test(value)) {
      return ctx.createError({
        message: "Email cannot contain spaces",
        path: `email`,
      });
    }
    return isEmailValid(value);
  }),
  role: string().min(2, "Must be at least 2 characters").matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid role").required("Role is a required field"),
  phoneNumber: string()
    .test("validate-phone-number", "Enter a valid phone number", (value, ctx) => {
      if (!value) return false;
      try {
        return validatePhoneNumber(value).isValid;
      } catch (error) {
        return ctx.createError({
          message: "Enter a valid phone number",
        });
      }
    })
    .required("Phone number is a required field"),
  firstName: string().min(2, "Must be at least 2 characters").matches(REGEX_NAME_SPECIAL_CHARS, "First name is invalid").required("First name is a required field"),
  lastName: string().min(2, "Must be at least 2 characters").matches(REGEX_NAME_SPECIAL_CHARS, "Last name is invalid").required("Last name is a required field"),
});
