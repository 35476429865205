import clsx from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { getFileName, getSignedUrl } from "utils/file";
import MonetLink from "./MonetLink";
import MonetLoadingSpinner from "./MonetLoadingSpinner";

type SignedUrlProps = {
  url?: string;
  className?: string;
};

const SignedUrl: React.FC<SignedUrlProps> = ({ url, className }) => {
  const [signedUrl, setSignedUrl] = useState<string>("#");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (url) {
      const getUrl = async () => {
        setIsLoading(true);
        await getSignedUrl(url)
          .then((res) => {
            setSignedUrl(res.url.href);
          })
          .catch(() => {
            console.debug("Failed to generate invoice document url");
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      getUrl();
    }
  }, [url]);

  return (
    <>
      {isLoading ? (
        <MonetLoadingSpinner size="x-small" />
      ) : url ? (
        <MonetLink href={signedUrl} className={twMerge(clsx("text-monet-blue h-full w-full", className))} showIcon={true}>
          <div className="block underline truncate">{getFileName(url)}</div>
        </MonetLink>
      ) : (
        <span>-</span>
      )}
    </>
  );
};

export default SignedUrl;
