import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useMemo } from "react";
import { Payee } from "shared/types/Payee";
import { Payout } from "shared/types/Payout";
import { toSentenceCase } from "utils/strings";

type ViewPayeeDetailsDrawerProps = {
  payoutType: Payout["payoutType"];
  payee?: Payee;
};

const ViewPayeeDetailsDrawer: React.FC<ViewPayeeDetailsDrawerProps> = ({ payoutType, payee }) => {
  const terminology = useMemo(() => {
    return payoutType === "VENDOR" ? "vendor" : "bank account";
  }, [payoutType]);

  return (
    <TailwindDrawer title={`${toSentenceCase(terminology)} details`}>
      <div className="flex flex-col gap-4 h-full">
        <MonetLabelledItem label="Alias" value={payee?.payeeAlias} />
        <MonetLabelledItem label="Associated email" value={payee?.email} />
        <MonetLabelledItem label="Account type" value={payee?.accountType} />
        <MonetLabelledItem label="Account number" value={payee?.accountNumber} />
        <MonetLabelledItem label="Sort code" value={payee?.routingNumber} />
        <MonetLabelledItem label="IBAN/Swift" value={payee?.bankSwiftBic} />
        <MonetLabelledItem label="Account currency" value={payee?.currency} />
        <MonetLabelledItem label="Bank account type" value={payee?.bankAccountType} />
      </div>
      <MonetDrawerButtons cancelDisabled={false} submitDisabled={true} isPassive={true} />
    </TailwindDrawer>
  );
};

export default ViewPayeeDetailsDrawer;
