import { AWS_PASSWORD_REGEX, REGEX_NAME_SPECIAL_CHARS, REGEX_URL } from "shared/constants/Regex";
import { validatePhoneNumber } from "shared/utils/phone";
import { getRequiredMessage, isEmailValid } from "shared/utils/validators";
import { SchemaOf, object, ref, string } from "yup";

export const userDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  passwordConfirmation: "",
};
export const companyDefaultValues = {
  country: "",
  companyName: "",
  turnover: "",
  industry: "",
  position: "",
  website: "",
};

export const signUpDefaultValues = {
  ...userDefaultValues,
  ...companyDefaultValues,
};

export type UserFormObject = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
};

export type CompanyObject = {
  country: string;
  companyName: string;
  turnover: string;
  industry: string;
  position: string;
  website: string;
};

export type SignUpFormObject = UserFormObject & CompanyObject;

export const userValidationSchema = <SchemaOf<UserFormObject>>object().shape({
  email: string().test("validate-email", "Please provide a valid email address", (value, ctx) => {
    if (!value)
      return ctx.createError({
        type: "required",
        message: "Email is a required field",
        path: `email`,
      });
    if (/\s/.test(value)) {
      return ctx.createError({
        message: "Email cannot contain spaces",
        path: `email`,
      });
    }
    return isEmailValid(value);
  }),
  password: string().matches(AWS_PASSWORD_REGEX, "Password is invalid").required("Password is a required field"),
  passwordConfirmation: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
  phoneNumber: string()
    .test("validate-phone-number", "Enter a valid phone number", (value, ctx) => {
      if (!value) return false;
      try {
        return validatePhoneNumber(value).isValid;
      } catch (error) {
        return ctx.createError({
          message: "Enter a valid phone number",
        });
      }
    })
    .required("Phone number is a required field"),
  firstName: string().min(2, "Must be at least 2 characters").matches(REGEX_NAME_SPECIAL_CHARS, "First name is invalid").required("First name is a required field"),
  lastName: string().min(2, "Must be at least 2 characters").matches(REGEX_NAME_SPECIAL_CHARS, "Last name is invalid").required("Last name is a required field"),
});

export const companyValidationSchema = <SchemaOf<CompanyObject>>object().shape({
  companyName: string()
    .min(2, "Must be at least 2 characters")
    .matches(/^([A-Za-z\d])[A-Za-z\d\s\-\(\)]+$/, "Enter a valid company name")
    .required("Company Name is a required field"),
  turnover: string().typeError(getRequiredMessage("Turnover")).required("Turnover is a required field"),
  country: string().typeError(getRequiredMessage("Country")).required("Country is a required field"),
  website: string()
    .test("validate-website", "Please enter a valid website URL", (val, ctx) => {
      if (!val) return false;
      if (!val?.match(REGEX_URL)) {
        return ctx.createError({
          type: "required",
          message: "Please enter a valid website URL",
          path: "website",
        });
      }

      return true;
    })
    .required("Website is a required field"),
  industry: string().typeError(getRequiredMessage("Industry")).required("Industry is a required field"),
  position: string().min(2, "Must be at least 2 characters").matches(REGEX_NAME_SPECIAL_CHARS, "Enter a valid role").required("Role is a required field"),
});

export const signUpValidationSchema = userValidationSchema.concat(companyValidationSchema);
