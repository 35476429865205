import { Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { Payout } from "types/Payout";

export const getPayoutTotalDetails = (payout: Payout) => {
  const invoiceTotal = payout.payoutValue as MoneyStorage;
  const monetFee = Percentage.fromStorageValue(payout.feeSettings.monetFee);
  const partnerFee = Percentage.fromStorageValue(payout.feeSettings.partnerFee);
  const chargeCreator = payout.feeSettings.chargeCreator;

  let monetFeeTotal = Money.fromStorageType(invoiceTotal as MoneyStorage).multiply(monetFee.percentage);

  if (monetFeeTotal.amount <= 0) {
    monetFeeTotal = new Money(0, invoiceTotal?.currency);
  }

  let partnerFeeTotal = Money.fromStorageType(invoiceTotal as MoneyStorage).multiply(partnerFee.percentage);

  let fees = monetFeeTotal.add(partnerFeeTotal);
  if (!chargeCreator) {
    fees = new Money(0, invoiceTotal?.currency);
    partnerFeeTotal = fees.subtract(monetFeeTotal);
  }

  const earlyPayTotal = Money.fromStorageType(invoiceTotal as MoneyStorage).subtract(chargeCreator ? fees : 0);

  return {
    earlyPayTotal,
    fees,
    partnerFee: partnerFeeTotal,
    monetFee: monetFeeTotal,
    payoutTotal: Money.fromStorageType(invoiceTotal as MoneyStorage).subtract(partnerFeeTotal.add(monetFeeTotal)),
  };
};

const generateRandomString = (length: number) => {
  const characters = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
  let result = "";
  const charLength = characters.length - 1;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (charLength - 0 + 1) + 0);
    result += characters.charAt(randomIndex);
  }

  return result;
};
