import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import MonetButton from "components/tailwind/MonetButton";
import MonetLink from "components/tailwind/MonetLink";
import StatusBadge from "components/tailwind/StatusBadge";
import TailwindDisclosure from "components/tailwind/headlessTailwind/TailwindDisclosure";
import { useMemo, useState } from "react";
import { CampaignDeliverable } from "shared/types/Campaign";

type DeliverablesModalProps = {
  invoiceDeliverables?: CampaignDeliverable[];
};

const DeliverablesModal: React.FC<DeliverablesModalProps> = ({ invoiceDeliverables }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deliverableItems = useMemo(() => {
    if (invoiceDeliverables) {
      return invoiceDeliverables?.map((deliverable) => (
        <div className="flex flex-row gap-4 w-full text-left">
          <TailwindDisclosure
            buttonElement={
              <div className="flex flex-row justify-between gap-4">
                <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
                  <StatusBadge type="deliverable" text={deliverable.status} />
                  <p className="text-sm text-left">{deliverable.name}</p>
                </div>
              </div>
            }
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm bg-gray-50 rounded-lg p-2">
              <div>
                <p className="font-semibold">Description</p>
                <div>{deliverable.description ? <p>{deliverable.description}</p> : <p>No description</p>}</div>
              </div>
              {deliverable.liveLinks && deliverable.liveLinks.length > 0 && (
                <div>
                  <p className="font-semibold">Links to live content</p>
                  <div>
                    {deliverable.liveLinks?.map((link) => (
                      <MonetLink key={link} href={link} showIcon={true} className="text-monet-blue w-full">
                        {link}
                      </MonetLink>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </TailwindDisclosure>
        </div>
      ));
    } else return null;
  }, [invoiceDeliverables]);

  return (
    <>
      <MonetButton variant="link" size="fit-content" className="font-normal" onClick={toggleModal}>
        View
      </MonetButton>
      <Dialog transition open={isModalOpen} onClose={toggleModal} className="relative z-50">
        <DialogBackdrop className="fixed inset-0 bg-black/30" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel
            transition
            className="flex flex-col bg-white border shadow-sm max-h-[500px] overflow-auto max-w-[60%] p-4 w-full rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70 transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex flex-col items-center gap-4">
              <div className="flex flex-col gap-4 w-full">{deliverableItems}</div>
              <div className="flex flex-row gap-4 w-full justify-end">
                <MonetButton variant="outlined" color="gray-outlined" className="w-fit" size="small" onClick={toggleModal}>
                  Close
                </MonetButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default DeliverablesModal;
