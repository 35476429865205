import { Button, TextField, Typography } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Partner } from "shared/types/Partner";
import { PARTNERS_LIST_ALL_PATH, PARTNERS_PATH } from "views/partners/PartnersPath";
import { useRemovePartnerMutation } from "views/partners/graphql/mutations.generated";

type DeletePartnerModalProps = {
  partner: Partner;
};

const DeletePartnerModal: React.FC<DeletePartnerModalProps> = ({ partner }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [partnerIdInput, setPartnerIdInput] = useState<string>();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [deletePartner, { loading: isDeletePartnerLoading }] = useRemovePartnerMutation();

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onDeletePartnerClicked = () => {
    deletePartner({
      variables: {
        partnerId: partnerIdInput!,
      },
    })
      .then(() => {
        setIsModalVisible(false);
        setPartnerIdInput(undefined);
        enqueueSnackbar(`${partner.name} was successfully removed!`, { variant: "success" });
        navigate(PARTNERS_PATH + PARTNERS_LIST_ALL_PATH);
      })
      .catch(() => {
        enqueueSnackbar(`There was a problem with removing ${partner.name} `, { variant: "error" });
      });
  };

  return (
    <>
      <Button data-testid="deletePartner-btn" variant="text" color="error" onClick={toggleModal}>
        <strong>Delete partner</strong>
      </Button>
      <CustomModal
        title="Are you sure you want to permanently delete the partner?"
        modalOpen={isModalVisible}
        isLoading={isDeletePartnerLoading}
        isConfirmBtnDisabled={isDeletePartnerLoading || partnerIdInput !== partner.partnerId}
        onCancelCallback={toggleModal}
        onConfirmCallback={onDeletePartnerClicked}
      >
        <Typography>
          You are about to permanently delete <strong>{partner.name}</strong>
        </Typography>
        <Typography fontWeight={600}>You will not be able to restore the partner later on and all payouts and data will be removed immediately</Typography>
        <Typography>Enter the partner ID to continue:</Typography>
        <TextField
          data-testid="deletePartner-partnerIdInput"
          placeholder="Enter partner ID"
          disabled={isDeletePartnerLoading}
          onChange={(e) => setPartnerIdInput(e.target.value)}
          fullWidth
        />
      </CustomModal>
    </>
  );
};

export default DeletePartnerModal;
