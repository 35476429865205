import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { CAMPAIGN_STATUSES, DEFAULT_DATE_FORMAT, SYSTEM_ALLOWED_CURRENCIES } from "shared/constants/Constants";

type CampaignData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignStatus: string;
  campaignName: string;
  brief: string;
  brand: string;
  clientLegalName: string;
  clientContact: string;
  clientContactEmail: string;
  campaignCurrency: string;
  campaignValue: MoneyStorage;
  valueOfFundedInvoices: MoneyStorage;
  totalFunding: MoneyStorage;
  fundingBalance: MoneyStorage;
  fundsAdvanced: MoneyStorage;
  pendingAdvancements: MoneyStorage;
  fundingSettled: MoneyStorage;
  usedFunds: MoneyStorage;
  campaignCreatedDate: string;
  campaignLastUpdated: string;
};

export const campaignSummaryCols: GridColDef<CampaignData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign Name",
    type: "string",
    minWidth: 250,
  },
  {
    field: "campaignStatus",
    headerName: "Status",
    type: "singleSelect",
    minWidth: 200,
    valueOptions: CAMPAIGN_STATUSES,
  },
  {
    field: "brief",
    headerName: "Brief",
    type: "string",
    minWidth: 200,
  },
  {
    field: "brand",
    headerName: "Brand promoted",
    type: "string",
    minWidth: 200,
  },
  {
    field: "clientLegalName",
    headerName: "Client",
    type: "string",
    minWidth: 250,
  },
  {
    field: "clientContact",
    headerName: "Client contact name",
    type: "string",
    minWidth: 200,
  },
  {
    field: "clientContactEmail",
    headerName: "Client contact email",
    type: "string",
    minWidth: 200,
  },
  {
    field: "campaignCurrency",
    headerName: "Campaign currency",
    type: "singleSelect",
    minWidth: 200,
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
  },
  {
    field: "campaignValue",
    headerName: "Campaign value",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.campaignValue as MoneyStorage);
    },
  },
  {
    field: "valueOfFundedInvoices",
    headerName: "Value of funded invoices",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.valueOfFundedInvoices as MoneyStorage);
    },
  },
  {
    field: "totalFunding",
    headerName: "Total funding",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.totalFunding, true);
    },
  },
  {
    field: "fundingBalance",
    headerName: "Funding balance",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.fundingBalance, true);
    },
  },
  {
    field: "fundsAdvanced",
    headerName: "Funds advanced",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.fundsAdvanced, true);
    },
    description: "The total value of associated payouts in statuses Beneficiary paid, Settled",
  },
  {
    field: "pendingAdvancements",
    headerName: "Pending advancements",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.pendingAdvancements, true);
    },
    description: "The total value of associated payouts in statuses Created, Beneficiary accepted, Monet accepted, Payment processing",
  },
  {
    field: "fundingSettled",
    headerName: "Funding settled",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<CampaignData>) => {
      return formatStorageType(params.row.fundingSettled, true);
    },
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "campaignCreatedDate",
    headerName: "Campaign created date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => dayjs(value, DEFAULT_DATE_FORMAT).toDate(),
    valueFormatter: (value, row) => dayjs(value).format(DEFAULT_DATE_FORMAT),
  },
  {
    field: "campaignLastUpdated",
    headerName: "Campaign last updated date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
];
