import { REGEX_URL } from "shared/constants/Regex";
import { PartnerAddressSchema } from "shared/utils/validators";
import { SchemaOf, array, object, string } from "yup";

export type OrganisationProfileDetailsFormObject = {
  name: string;
  countryOfRegistration: string;
  companyWebsite: string;
  turnover: string;
  functionalCurrencies: string[];
  industry: string;
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode: string;
  };
};

export const organisationProfileDetailsSchema = <SchemaOf<OrganisationProfileDetailsFormObject>>object().shape({
  name: string()
    .min(2, "Must be at least 2 characters")
    .matches(/^([A-Za-z\d])[A-Za-z\d\s-]+$/, "Enter a valid company name")
    .required("Company Name is a required field"),
  countryOfRegistration: string().required("Please select a country of registration"),
  address: PartnerAddressSchema,
  industry: string().required("Please select an industry"),
  turnover: string().required("Please select a turnover"),
  functionalCurrencies: array()
    .of(string().required("Please select at least 1 currency"))
    .test("min-test", "Please select at least one currency", (value) => {
      if (!value || value.length <= 0) return false;
      return true;
    }),
  companyWebsite: string().test("validate-website", "Please enter a valid website address", (val, ctx) => {
    if (!val) return false;
    if (!val?.match(REGEX_URL)) {
      return false;
    }
    return true;
  }),
});
