import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, CircularProgress, FormControl, Stack, styled, Typography } from "@mui/material";
import { ReactComponent as UploadIcon } from "assets/upload-icon.svg";
import { TextField } from "mui-rff";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, useFormState } from "react-final-form";
import { MAX_FILE_SIZE_IN_MB } from "shared/constants/Constants";
import { getFileName, getFileSizeInMB, MAX_FILE_SIZE_ERROR_MESSAGE, taggingHeaderKey } from "utils/file";
import { useGeneratePartnerDocumentUploadUrlMutation, useRemovePartnerDocumentMutation } from "views/partners/graphql/mutations.generated";

const DropzoneContainer = styled(Stack, {
  shouldForwardProp: (propertyName) => !["hasFile"].includes(propertyName.toString()),
})<{ hasFile: boolean }>(({ theme, hasFile }) => ({
  alignItems: "center",
  border: hasFile ? `1px solid ${theme.palette.common.black}` : `1px ${theme.palette.primary.main} dotted`,
  borderSpacing: 4,
  color: hasFile ? theme.palette.common.black : undefined,
  minHeight: 60,
  fontWeight: 600,
  "& button": {
    fontWeight: 600,
    padding: theme.spacing(0, 2),
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const FileNameBox = styled(Stack, {
  shouldForwardProp: (propertyName) => !["hasFile"].includes(propertyName.toString()),
})<{ hasFile: boolean }>(({ theme, hasFile }) => ({
  border: `1px solid ${hasFile ? theme.palette.success.main : theme.palette.primary.main}`,
  color: hasFile ? theme.palette.success.main : undefined,
  width: "40%",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  "& *": {
    fontWeight: hasFile ? 600 : undefined,
  },
}));

const DottedCircle = styled(Box)(({ theme }) => ({
  border: `2px dotted ${theme.palette.common.black}`,
  backgroundColor: "transparent",
  height: 20,
  borderRadius: "50%",
  width: 20,
}));

const fileTypeErrorMessage = "Invalid file type. Accepted file types are docx, doc, pdf";
const acceptedFileExtensions = ["doc", "docx", "pdf"];
const AgreementTermsUploadField: React.FC<{ title: string; isNew?: boolean; name: string }> = ({ title, isNew = true, name }) => {
  const { change } = useForm();
  const { values } = useFormState();
  const [generateUploadUrl] = useGeneratePartnerDocumentUploadUrlMutation({
    fetchPolicy: "no-cache",
  });

  const [removeDocumentFromS3] = useRemovePartnerDocumentMutation();
  const [isLoading, setIsLoading] = useState(false);
  const fileDetails = values?.defaultConfiguration?.invoiceAssignmentTerms?.[name] || "";

  const { enqueueSnackbar } = useSnackbar();

  const fieldName = useMemo(() => `defaultConfiguration.invoiceAssignmentTerms.${name}`, [name]);

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      try {
        const fileNameArray = file.name.split(".");

        const fileExt = fileNameArray[fileNameArray.length - 1].toLowerCase();

        if (!acceptedFileExtensions.includes(fileExt)) {
          enqueueSnackbar(fileTypeErrorMessage, {
            variant: "error",
          });
          return;
        }

        if (getFileSizeInMB(file.size) > MAX_FILE_SIZE_IN_MB) {
          enqueueSnackbar(MAX_FILE_SIZE_ERROR_MESSAGE, {
            variant: "error",
          });
          return;
        }

        setIsLoading(true);

        const fileName = `${values.partnerId}/${file.name}`;
        // generate upload url
        const urlResponse = await generateUploadUrl({
          variables: {
            category: "INVOICE_FINANCE_TERMS",
            fileName,
          },
        });

        if (!urlResponse.data?.generateDocumentUploadUrl) {
          enqueueSnackbar("Error generating upload url", {
            variant: "error",
          });
          return;
        }
        // upload file
        const url = urlResponse.data.generateDocumentUploadUrl;

        await fetch(url, {
          method: "PUT",
          body: file,
          headers: taggingHeaderKey,
        }).then((res) => {
          if (res.ok) {
            return res;
          } else {
            throw new Error(res.statusText);
          }
        });

        const bucketKey = url.split("?")[0];

        change(fieldName, bucketKey);
        setIsLoading(false);
      } catch (error: any | Error) {
        enqueueSnackbar(error?.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    },
    [values.partnerId],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const removeFile = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsLoading(true);
      try {
        if (isNew) {
          await removeDocumentFromS3({
            variables: {
              bucketKey: fileDetails,
            },
          });
        }

        change(fieldName, "");
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Error removing file", {
          variant: "error",
        });
        setIsLoading(false);
      }
    },
    [fileDetails],
  );

  return (
    <Box>
      <Stack direction="column">
        <FormControl>
          <Stack direction={{ md: "row" }}>
            <FileNameBox direction="row" hasFile={!!fileDetails} spacing={2}>
              {fileDetails ? <CheckSharpIcon color="success" height={20} /> : <DottedCircle />}
              <Typography>{title}</Typography>
            </FileNameBox>

            <Box {...getRootProps()} width="100%">
              <input {...getInputProps()} multiple={false} />

              <DropzoneContainer hasFile={!!fileDetails} justifyContent={fileDetails ? "space-between" : "center"}>
                {fileDetails && <Typography px={2}>{getFileName(fileDetails)}</Typography>}
                {!fileDetails && (
                  <>
                    {isDragActive ? (
                      <Button variant="text">Drop the files here ...</Button>
                    ) : (
                      <Button variant="text" data-testid="partnerJourney-partnerAssignmentTerms">
                        <UploadIcon />
                        <span>Click to upload</span>
                      </Button>
                    )}
                  </>
                )}

                {isLoading && <CircularProgress size={16} />}

                {fileDetails && (
                  <Button disabled={isLoading} variant="text" color="error" onClick={removeFile}>
                    <ClearIcon /> Remove
                  </Button>
                )}
              </DropzoneContainer>
            </Box>
          </Stack>
        </FormControl>

        <Box display="none">
          <TextField name={fieldName} autoComplete="off" />
        </Box>
      </Stack>
    </Box>
  );
};

export default AgreementTermsUploadField;
