import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as MinusIcon } from "assets/tailwind/icons/minus-circle.svg";
import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetFileInput from "components/tailwind/form/MonetFileInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { isInPartnerContext } from "utils/User";
import { useUploadCampaignDocumentsMutation } from "views/campaigns/graphql/mutations.generated";
import { uploadCampaignFiles } from "views/campaigns/utils";
import { CampaignDocuments, uploadCampaignDocumentSchema } from "views/campaigns/validationSchema/UploadCampaignDocumentSchema";

type DocumentUploadDrawerProps = {
  refetchCampaign(): void;
  campaign?: Campaign;
};

const DocumentUploadDrawer: React.FC<DocumentUploadDrawerProps> = ({ campaign, refetchCampaign }) => {
  const [uploadDocuments] = useUploadCampaignDocumentsMutation();

  const { user } = useSessionProvider();
  const { closeModal } = useModal();

  const onSubmit = async (formData: any) => {
    if (campaign) {
      try {
        const res = await uploadCampaignFiles<CampaignDocuments[]>(formData.documents, user, campaign.campaignId);
        if (res.length > 0) {
          await uploadDocuments({
            variables: {
              partnerId: campaign.partnerId,
              campaignId: campaign.campaignId,
              documents: res,
            },
          });
          refetchCampaign();
          closeModal();
          toast.success("Documents uploaded");
        } else throw new Error();
      } catch (err: Error | any) {
        toast.error("Error uploading documents", { description: "we were unable to upload the documents. Please try again" });
      }
    }
  };

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(uploadCampaignDocumentSchema),
    defaultValues: {
      documents: [
        {
          file: "",
          fileType: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "documents",
  });

  if (!isInPartnerContext(user) || !campaign) return null;

  if (campaign.campaignStatus === "COMPLETED") return null;

  return (
    <TailwindDrawer title="Document upload" subtitle="Upload any supporting documents for this campaign and select the document type">
      <FormProvider {...methods}>
        <form className="flex flex-col flex-1 justify-between mt-4" onSubmit={methods.handleSubmit(onSubmit)}>
          <ul className="flex flex-col gap-4">
            {fields.map((field, index) => (
              <li key={field.id}>
                <div className="flex flex-col items-center relative sm:flex-row gap-2">
                  {index > 0 && (
                    <MonetButton
                      variant="solid"
                      size="fit-content"
                      className="bg-red-600 hover:bg-red-700 text-white rounded-full absolute top-0 left-[-5px] z-10"
                      onClick={() => {
                        remove(index);
                      }}
                      disabled={methods.formState.isSubmitting}
                    >
                      <MinusIcon width={14} height={14} />
                    </MonetButton>
                  )}
                  <MonetFileInput size="small" {...methods.register(`documents.${index}.file`)} disabled={methods.formState.isSubmitting} />
                  <Controller
                    control={methods.control}
                    name={`documents.${index}.fileType`}
                    render={({ field: { ...inputProps } }) => (
                      <TailwindSelectInput
                        inputProps={inputProps}
                        size="small"
                        dropdownClassName="w-fit"
                        placeholder="Type..."
                        options={[
                          { label: "Contract", value: "contract" },
                          { label: "Client sign off", value: "sign-off" },
                          { label: "Purchase order", value: "purchase-order" },
                          { label: "Other", value: "other" },
                        ]}
                        disabled={methods.formState.isSubmitting}
                      />
                    )}
                  />
                </div>
                <div>
                  <p className="text-sm text-red-500 mt-2">{methods.formState.errors.documents?.[index]?.file?.message as string}</p>
                  <p className="text-sm text-red-500 mt-2">{methods.formState.errors.documents?.[index]?.fileType?.message as string}</p>
                </div>
              </li>
            ))}
            <MonetButton
              size="x-small"
              variant="outlined"
              className="self-end"
              onClick={() => {
                append({ file: "", fileType: "" });
              }}
              disabled={methods.formState.isSubmitting}
            >
              <PlusIcon /> Add another document
            </MonetButton>
          </ul>
          <MonetDrawerButtons
            drawerId="campaign-document-upload"
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting}
            loading={methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default DocumentUploadDrawer;
