import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import StatusBadge from "components/tailwind/StatusBadge";
import { useSessionProvider } from "contexts/SessionProvider";
import { generatePath, useNavigate } from "react-router-dom";
import { Partner } from "shared/types/Partner";
import PartnerIconAndName from "views/partners/ListPartners/PartnerIconAndName";
import { PARTNERS_DETAILS_PATH, PARTNERS_PATH } from "../PartnersPath";

type PartnersTableProps = {
  partners: Partner[];
  isLoading?: boolean;
};

const PartnersTable: React.FC<PartnersTableProps> = ({ partners, isLoading }) => {
  const navigate = useNavigate();
  const { user } = useSessionProvider();

  const columns: GridColDef<Partner>[] = [
    {
      field: "name",
      headerName: "Partner",
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<Partner>) => {
        return <PartnerIconAndName partner={params.row} />;
      },
      sortComparator: (a, b) => {
        return a.localeCompare(b);
      },
    },
    {
      field: "partnerId",
      headerName: "Partner ID",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "limits",
      minWidth: 150,
      headerName: "Credit limit",
      type: "number",
      width: 175,
      valueGetter: (value, row) => {
        return Number(Money.fromStorageType(row.limits?.global as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Partner>) => {
        return formatStorageType(params.row.limits?.global as MoneyStorage);
      },
    },
    {
      field: "kybStatus",
      minWidth: 150,
      type: "singleSelect",
      valueOptions: ["VERIFIED", "NOT_VERIFIED"],
      renderCell: (params: GridRenderCellParams<Partner>) => {
        return <StatusBadge type="partner" text={params.row.kybStatus} />;
      },
      headerName: "KYB",
    },
  ];

  return (
    <CustomDataGrid
      rows={partners}
      columns={columns}
      autoPageSize={true}
      loading={isLoading}
      getRowId={(row: Partner) => row.partnerId}
      onRowClick={(params: GridRowParams<Partner>) =>
        navigate(
          generatePath(PARTNERS_PATH + PARTNERS_DETAILS_PATH, {
            partnerId: params.row.partnerId,
          }),
        )
      }
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      sx={{ minHeight: 500 }}
    />
  );
};

export default PartnersTable;
