import { Currency, Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CountryNameAndFlag } from "components/CountryFlag/CountryFlag";
import ErrorView from "components/ErrorView/ErrorView";
import FlexRow from "components/FlexRow/FlexRow";
import ItemBox from "components/ItemBox/ItemBox";
import LoadingView from "components/Loaders/LoadingView";
import ViewHeader from "components/PageElements/ViewHeader";
import CenteredMainContainer from "components/utils/CenteredMainContainer";
import { IconAndText } from "components/utils/IconAndText";
import StackWithBorder from "components/utils/StackWithBorder";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CurrencyWithMargin } from "shared/types/Currency";
import { Partner } from "shared/types/Partner";
import { PartnerDefaultConfiguration, getTurnoversForInput } from "utils/Partner";
import { SelectedCurrency, formatAllowedCurrenciesForForm, getFullCurrencyWithMargin } from "utils/currency";
import { enumToNiceString, formatURL } from "utils/strings";
import EditPartner from "../EditPartner/EditPartner";
import AllowedCurrenciesList from "../components/AllowedCurrenciesList";
import { useGetPartnerQuery } from "../graphql/queries.generated";
import InvoiceAssignmentTerms from "./InvoiceAssignmentTerms";
import PartnerUsers from "./PartnerUsers";

const PartnerDetails: React.FC = () => {
  const [partner, setPartner] = useState<Partner>();
  const [editing, setEditing] = useState<boolean>(false);
  const { partnerId } = useParams();
  const {
    data: partnersData,
    error: partnersError,
    loading: isLoadingPartners,
    refetch: refetchPartners,
  } = useGetPartnerQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId: partnerId!,
    },
  });

  useEffect(() => {
    if (partnersData) {
      const partnerDetails = (JSON.parse(partnersData.getPartner) as Partner[]).find((partner) => partner.partnerId === partnerId);
      if (partnerDetails && !partnerDetails.defaultConfiguration) {
        partnerDetails.defaultConfiguration = PartnerDefaultConfiguration;
      }
      setPartner(partnerDetails as Partner);
    }
  }, [partnersData]);

  const toggleEditing = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const editSuccess = useCallback(() => {
    setEditing(!editing);
    refetchPartners();
  }, [editing]);

  const allowedCurrencies = useMemo(() => {
    if (!partner) return [];
    return formatAllowedCurrenciesForForm(partner.defaultConfiguration?.allowedCurrencies, true);
  }, [partner?.defaultConfiguration.allowedCurrencies]);

  const getTurnover = useMemo(() => {
    if (!partner?.businessMetadata) return "";

    return getTurnoversForInput(partner.businessMetadata.countryOfRegistration).find((x) => x.value == partner.businessMetadata?.turnover)?.label ?? "";
  }, [partner?.businessMetadata]);

  const hideDetails = useMemo(() => {
    return partner?.kybStatus == "NOT_VERIFIED";
  }, [partner]);

  const selectedCurrencies = useMemo(() => getFullCurrencyWithMargin(allowedCurrencies as unknown as CurrencyWithMargin[]) as unknown as SelectedCurrency[], [allowedCurrencies]);

  if (isLoadingPartners) return <LoadingView title="Loading partner" />;

  if (partnersError) return <ErrorView title="Error loading payouts" error={partnersError} />;

  if (!partner) return <ErrorView title="Error loading partner" />;

  return (
    <CenteredMainContainer>
      <ViewHeader
        header={partner.partnerId}
        button={
          <Button variant="contained" data-testid="editPartner-btn" onClick={toggleEditing} disabled={editing}>
            Edit partner
          </Button>
        }
      />
      {editing && <EditPartner partner={partner} toggleEdit={toggleEditing} successCallback={editSuccess} />}
      {!editing && (
        <Stack spacing={3}>
          <StackWithBorder title="Details">
            <FlexRow>
              <ItemBox title="Partner name" value={partner.name} />
              {partner.address?.country && <ItemBox title="Location of partner" value={<CountryNameAndFlag countryCode={partner.address.country} />} />}
              {partner.defaultConfiguration.logoUrl && (
                <ItemBox title="Partner logo" value={<img src={partner.defaultConfiguration.logoUrl} style={{ maxWidth: 50, maxHeight: 50 }} />} />
              )}
              <ItemBox title="Partner ID" value={partner.partnerId} />
            </FlexRow>
          </StackWithBorder>
          <StackWithBorder isHidden={hideDetails} title="Address">
            <FlexRow>
              {partner.address && (
                <ItemBox
                  title="Partner address"
                  value={`${partner.address.addressLine1}, ${partner.address?.addressLine2 && `${partner.address?.addressLine2},`} ${partner.address.postcode}, ${
                    partner.address.city
                  }`}
                />
              )}
            </FlexRow>
          </StackWithBorder>
          <StackWithBorder title="Fee rules" isHidden={hideDetails}>
            <FlexRow>
              {partner.limits?.global && (
                <ItemBox title="Credit limit" value={new Money(partner.limits?.global.amount || 0, partner.limits?.global.currency as Currency).format()} />
              )}
              {partner.defaultConfiguration.feeSettings && (
                <>
                  <ItemBox title="Maximum fee" value={Percentage.fromStorageValue(partner.defaultConfiguration.feeSettings.max).format()} />
                  <ItemBox title="Default fee" value={Percentage.fromStorageValue(partner.defaultConfiguration.feeSettings.default).format()} />
                </>
              )}
            </FlexRow>
          </StackWithBorder>
          <StackWithBorder title="EarlyPay Collection Accounts">
            <FlexRow>
              <ItemBox
                title="Added accounts"
                value={
                  <Box>
                    {partner.collectionAccounts?.map((account) => (
                      <Typography data-testid={`collectionAccountsView-${account.currency}`} variant="h6" key={account.currency}>
                        {account.currency} Account ({getSymbolFromCurrency(account.currency)})
                      </Typography>
                    ))}

                    {!partner.collectionAccounts?.length && <Typography variant="h6">No accounts added</Typography>}
                  </Box>
                }
              />
            </FlexRow>
          </StackWithBorder>
          {partner?.businessMetadata && (
            <StackWithBorder title="Business Metadata">
              <FlexRow>
                <ItemBox title="Country of registration" value={<CountryNameAndFlag countryCode={partner?.businessMetadata?.countryOfRegistration} />} />
                <ItemBox title="Industry" value={partner?.businessMetadata?.industry} />
                <ItemBox title="Turnover" value={getTurnover} />
              </FlexRow>
              <FlexRow>
                <ItemBox title="Website" value={partner?.businessMetadata?.companyWebsite} />
              </FlexRow>
            </StackWithBorder>
          )}
          <StackWithBorder title="KYB status">
            {partner.kybStatus && <ItemBox title="Status of company" value={enumToNiceString(partner.kybStatus).toUpperCase()} />}
          </StackWithBorder>
          <StackWithBorder title="Configuration" isHidden={hideDetails}>
            <FlexRow>
              <ItemBox title="Default logo" value={<img style={{ maxWidth: 150 }} src={partner.defaultConfiguration?.logoUrl} />} />
              <ItemBox title="Payout TTL delta" value={partner.defaultConfiguration?.payoutTtlDelta} />
              <ItemBox title="Allowed currencies" value={<AllowedCurrenciesList selectedCurrencies={selectedCurrencies} />} />
            </FlexRow>
            <ItemBox
              title="Default creator terms & conditions"
              value={
                <IconAndText iconMargin={1}>
                  <AttachFileIcon />
                  <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    <a target="_blank" href={formatURL(partner.defaultConfiguration?.termsAndConditions?.link as string)}>
                      {partner.defaultConfiguration?.termsAndConditions?.link}
                    </a>
                  </Typography>
                </IconAndText>
              }
            />
            <ItemBox
              title="Default invoice assignment terms"
              value={
                <IconAndText iconMargin={1}>
                  <InvoiceAssignmentTerms partnerId={partnerId!} />
                </IconAndText>
              }
            />
          </StackWithBorder>
          <PartnerUsers partnerId={partnerId!} partnerKybStatus={partner.kybStatus!} />
        </Stack>
      )}
    </CenteredMainContainer>
  );
};

export default PartnerDetails;
