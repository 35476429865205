import { useSessionProvider } from "contexts/SessionProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Partner } from "shared/types/Partner";
import { toast } from "sonner";
import { isPartnerVerified } from "utils/Partner";
import { PAYOUTS_PATH, PAYOUT_LIST_ALL_PATH } from "views/payouts/PayoutPath";

type OnboardingRequiredRouteProps = {
  redirectPath: string;
};

const OnboardingRequiredRoute: React.FC<OnboardingRequiredRouteProps> = ({ redirectPath }) => {
  const { pathname } = useLocation();
  const { partner, user } = useSessionProvider();

  if ([`${PAYOUTS_PATH}${PAYOUT_LIST_ALL_PATH}`].includes(pathname)) {
    return <Outlet />;
  }

  const restrictRoute = (partner?: Partner): boolean => {
    return !isPartnerVerified(partner, user);
  };

  const redirectToPath = () => {
    toast.error("Please complete onboarding to access this area of the platform");
    return <Navigate to={redirectPath} />;
  };

  return restrictRoute(partner) ? redirectToPath() : <Outlet />;
};

export default OnboardingRequiredRoute;
