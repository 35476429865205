import { Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDivider from "components/tailwind/MonetDivider";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetDatePicker from "components/tailwind/form/MonetDatePicker";
import MonetTextArea from "components/tailwind/form/MonetTextArea";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DEFAULT_NUMERIC_DATE, UNSETTLED_PAYOUT_STATUSES } from "shared/constants/Constants";
import { calculateDFF, calculateReconcileDaysElapsed, calculateReconciliationFields } from "shared/logic/calculate";
import { Invoice } from "shared/types/Invoice";
import { Payout } from "shared/types/Payout";
import { toast } from "sonner";
import { useSettleInvoiceMutation } from "views/campaigns/graphql/mutations.generated";
import { useGetPartnerFeesLazyQuery } from "views/campaigns/graphql/queries.generated";

type SettleInvoiceDrawerProps = {
  refetchInvoices(): void;
  refetchPayouts(): void;
  refetchCampaign(): void;
  invoice: Invoice;
  payouts?: Payout[];
};

const SettleInvoiceDrawer: React.FC<SettleInvoiceDrawerProps> = ({ invoice, payouts, refetchInvoices, refetchPayouts, refetchCampaign }) => {
  const [monetDailyFee, setMonetDailyFee] = useState<Percentage>();
  const [daysElapsed, setDaysElapsed] = useState(0);

  const [getPartnerFees, { data: partnerFeesData }] = useGetPartnerFeesLazyQuery({ fetchPolicy: "network-only" });
  const [settleInvoice] = useSettleInvoiceMutation();

  const { closeModal } = useModal();

  // Get partner fees
  useEffect(() => {
    if (invoice) {
      getPartnerFees({
        variables: {
          partnerId: invoice.partnerId,
        },
      });
    }
  }, [invoice]);

  useEffect(() => {
    if (partnerFeesData) {
      const fees = partnerFeesData.GetPartnerFees;
      const feePerSelectedInvoice = fees.find((item) => item.currency == invoice?.invoiceValue.currency);
      if (feePerSelectedInvoice) {
        setMonetDailyFee(calculateDFF(feePerSelectedInvoice.margin, feePerSelectedInvoice.baseRate));
      }
    }
  }, [partnerFeesData]);

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm<{ settlementNote?: string; reconciliationDate: string[] }>({
    mode: "onTouched",
    defaultValues: {
      reconciliationDate: [dayjs().toISOString()],
    },
  });

  const reconciliationDate = watch("reconciliationDate");

  const reconFields = useMemo(() => {
    if (invoice && payouts && monetDailyFee) {
      const daysPastDue = calculateReconcileDaysElapsed(invoice.dueDate, dayjs(reconciliationDate[0]).format(DEFAULT_NUMERIC_DATE));
      setDaysElapsed(daysPastDue);
      return calculateReconciliationFields(invoice.invoiceValue, invoice.invoiceId, payouts, daysPastDue, monetDailyFee);
    }
    return undefined;
  }, [invoice, payouts, monetDailyFee, reconciliationDate]);

  const invoicePayouts = useMemo(() => {
    return payouts?.filter((payout) => payout.invoiceId === invoice?.invoiceId);
  }, [payouts, invoice]);

  const hasUnsettledPayouts = useMemo(() => {
    if (invoicePayouts) {
      if (invoicePayouts.length <= 0) {
        return true;
      } else return invoicePayouts.filter((x) => UNSETTLED_PAYOUT_STATUSES.includes(x.payoutStatus) && x.invoiceId === invoice.invoiceId).length > 0;
    } else return true;
  }, [invoicePayouts, invoice]);

  const allChecksToZeroValue = useMemo(() => {
    if (!reconFields?.allChecksToZero) {
      return undefined;
    } else return +reconFields?.allChecksToZero.format(false, false) >= 0 ? "Yes" : `No (${reconFields.allChecksToZero.format()})`;
  }, [reconFields]);

  const onSubmit = async (formData: { settlementNote?: string; reconciliationDate: string[] }) => {
    if (invoice) {
      try {
        await settleInvoice({
          variables: {
            invoice: {
              invoiceId: invoice.invoiceId,
              notes: formData.settlementNote,
              reconciliationDate: formData.reconciliationDate[0],
            },
            campaignId: invoice.campaignId,
            partnerId: invoice.partnerId,
          },
        });
        toast.success("Success", { description: "The invoice has been successfully settled" });
        refetchInvoices();
        refetchPayouts();
        refetchCampaign();
        closeModal();
      } catch (err: any) {
        toast.error("Request failed", { description: err.message });
      }
    }
  };

  if (invoice.funding?.fundingStatus !== "ACTIVE") return null;

  return (
    <>
      <TailwindDrawer title="Settle invoice" subtitle="Reconcile and settle this invoice confirming all the outstanding invoice value has been collected and accounted for">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 h-full">
          <div className="flex flex-col gap-4 h-full overflow-y-auto">
            {hasUnsettledPayouts ? (
              <MonetAlert
                variant="warning"
                title="Unsettled payouts"
                message="This invoice has either no payouts or inflight payouts associated with it. Please complete all payouts before proceeding to settle this invoice."
              />
            ) : (
              <>
                <MonetBorderedCardContent>
                  <MonetLabelledItem label="Invoice ID" value={invoice?.invoiceId} tooltip="This is the MONET created unique ID for this invoice" />
                  <MonetLabelledItem label="Invoice value" value={Money.fromStorageType(invoice?.invoiceValue).format(true, true)} />
                  <MonetLabelledItem label="Due date" value={dayjs(invoice?.dueDate).format("DD/MM/YYYY")} />
                </MonetBorderedCardContent>
                <Controller
                  control={control}
                  name="reconciliationDate"
                  rules={{
                    required: { value: true, message: "Please select a reconciliation date" },
                  }}
                  render={({ field: { ...inputProps } }) => (
                    <MonetDatePicker
                      key="reconciliationDate"
                      inputProps={inputProps}
                      disabled={isSubmitting}
                      label="Reconciliation date"
                      pickerOptions={{
                        maxDate: dayjs().toISOString(),
                        mode: "single",
                      }}
                      error={errors.reconciliationDate?.message}
                    />
                  )}
                />
                <MonetBorderedCardContent>
                  <MonetLabelledItem label="Days late" value={daysElapsed} />
                  <MonetLabelledItem label="Principal" value={reconFields?.principal.format(true, true)} />
                  <MonetLabelledItem label="EarlyPay fees" value={reconFields?.earlyPayFees.format(true, true)} />
                  <MonetLabelledItem label="EarlyPay late fees" value={reconFields?.earlyPayLateFees.format(true, true)} />
                  <MonetDivider />
                  <MonetLabelledItem label="Total EarlyPay fees" value={reconFields?.totalEarlyPayFees.format(true, true)} />
                  <MonetDivider />
                  <MonetLabelledItem label="Total agency fees" value={reconFields?.partnerFee.format(true, true)} />
                  <MonetLabelledItem label="Residual" value={reconFields?.residual.format(true, true)} />
                  <MonetDivider />
                  <MonetLabelledItem label="All checks to zero" value={allChecksToZeroValue} />
                </MonetBorderedCardContent>
                <MonetTextArea
                  {...register("settlementNote")}
                  label="Note"
                  id="settlementNote"
                  isOptional={true}
                  tooltip="Any notes left here will be visible to the partner"
                  error={errors.settlementNote?.message}
                  disabled={isSubmitting}
                />
              </>
            )}
          </div>
          <MonetDrawerButtons cancelDisabled={isSubmitting} submitDisabled={isSubmitting || !isValid || hasUnsettledPayouts} loading={isSubmitting} />
        </form>
      </TailwindDrawer>
    </>
  );
};

export default SettleInvoiceDrawer;
